import React, { useContext, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils';
import { getExStudentByStuNoPwd, getExTeacherByTNoPwd, getUpdExStuLearnRec } from '../../services/ExStuTeService';
import FooterPage from '../footer';
import { BSModal } from '../../components/modal/bsmodal';
import { getExStuTestCntBySid } from '../../services/ExStuRecService';
import  config from '../../config/config';
import { addAdLogin } from '../../services/AdLoginService';
import { getMyip } from '../../services/AdSessionService';
import { set } from 'date-fns';
import { ObjectModal } from '../../components/modal/objectmodal';
import { getAuthData } from '../ep/tool/helper';
import { MyContext } from '../home';


const LoginPage: React.FunctionComponent<IPageProps> = props => {
    const context = useContext(MyContext);
    const [account,setAccount]=useState("");
    const [pwd,setPwd]=useState("")
    const [pressed,setPressed]=useState(false);
    const [openModal,setOpenModal]=useState(false);
    const [errMsg,setErrMsg]=useState("");
    const [bindMail,setBindMail]=useState("");
    const [openObjectModal,setOpenObjectModal]=useState(false);

    const handleAccInp=(e:React.FormEvent<HTMLInputElement>)=>{
        setAccount(e.currentTarget.value)
    }
    const handlePwdInp=(e:React.FormEvent<HTMLInputElement>)=>{
        setPwd(e.currentTarget.value)
    }

    const redirGoogle=()=>{
        //console.log(config.server.url+config.localAuth.signin)
        handleLink(config.server.url+config.localAuth.signgooglein+"/0:new");
    }
    const redirTParty=()=>{
        handleLink(config.server.url+config.localAuth.signntpcin);        
       // const url=config.oAuth.AuthHost+"/oauth/authorize.php?client_id="+config.oAuth.ClientID+"&response_type=code&redirect_uri="+config.oAuth.RedirectURL+"&scope=User.Mail,User.BasicInfo,User.Application,UserInfo&linkSignIn="
       // handleLink(url);
    }

    // LoginState in session variables
    // 0: guest 
    // 1: student
    // 2: teacher
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const chkLogin=()=>{

        if((account!=="")&&(pwd!==""))
        {                  
            setPressed(true);      
      
            //handleLink("/");            
        }
        else
        {
            setOpenModal(true);
            if((account==="") &&(pwd===""))
            {
                setErrMsg("帳號及密碼不可空白");
            }
            else
            {
                if((account==="")) setErrMsg("帳號不可空白");
                if((pwd==="")) setErrMsg("密碼不可空白");
            }
            //toggleModal();

        }

    }

    useEffect(()=>{
        if(pressed)
        {
            const fetchdata=async()=>{
                const tmpStu=await getExStudentByStuNoPwd(account.toLowerCase().trim(), pwd.trim())
                const tmpTea=await getExTeacherByTNoPwd(account.toLowerCase().trim(), pwd.trim())
                
                if((tmpStu===undefined)&&(tmpTea===undefined)) 
                {
                    sessionStorage.setItem("LoginStatus","0");
                    sessionStorage.setItem("LoginID","");
                    sessionStorage.setItem("LoginSchoolNo","");
                    sessionStorage.setItem("BindError","");
                    
                    setErrMsg("帳號及密碼錯誤");
                    setOpenModal(true);
                    setPressed(false);
                    return;
                }
                if((tmpStu!==undefined)&&(tmpTea===undefined))  // 登入身分為學生                   
                {
                    sessionStorage.setItem("LoginStatus","1");   // 登入身分為學生
                    sessionStorage.setItem("LoginID",tmpStu.Sid.toString());
                    sessionStorage.setItem("LoginSchoolNo",tmpStu.SchoolNo);
                    sessionStorage.setItem("LoginStudent",JSON.stringify(tmpStu));
                    sessionStorage.setItem("LearnRec",JSON.stringify(getUpdExStuLearnRec(tmpStu.Sid.toString())));
                    sessionStorage.setItem("BindError","");
                    
                    getMyip().then(data => {
                        if (data.ip !== '::1') {
                            const ippart=data.ip.split(":")
                            addAdLogin(tmpStu.Sid.toString(),'E',ippart[3]);
                        }
                    })
                    setPressed(false);
                    handleLink("/");
                }
                if((tmpStu===undefined)&&(tmpTea!==undefined))  // 登入身分為教師                   
                {
                    sessionStorage.setItem("LoginStatus","2");   // 登入身分為教師
                    sessionStorage.setItem("LoginID",tmpTea.Tid.toString());
                    sessionStorage.setItem("LoginSchoolNo",tmpTea.SchoolNo);
                    sessionStorage.setItem("LoginTeacher",JSON.stringify(tmpTea));
                    sessionStorage.setItem("BindError","");
                    getMyip().then(data => {
                        if (data.ip !== '::1') {
                            const ippart=data.ip.split(":")
                            addAdLogin(tmpTea.Tid.toString(),'T',ippart[3]);
                        }
                    })

                    setPressed(false);
                    handleLink("/");
                }
                if((tmpStu!==undefined)&&(tmpTea!==undefined))  // 學生與教師帳號重複                   
                {
                    sessionStorage.setItem("LoginStatus","0");
                    sessionStorage.setItem("LoginID","");
                    sessionStorage.setItem("LoginSchoolNo","");
                    
                    setErrMsg("學生與教師帳號重複");
                    setOpenModal(true);
                    setPressed(false);
                    return;
                }
            }
            fetchdata();        
        }
        const getCtrls = async (control: string) => {
            let ctrlInfo = await getAuthData(control);
            context?.setCtrlInfoList(ctrlInfo);
            // alert(JSON.stringify(context?.crtlInfoList))
        };
      
            getCtrls(sessionStorage.getItem('role') || '');
   
    }
    ,[pressed]);

    /** 初始化 清空記憶體 */
    useEffect(()=>{
        sessionStorage.setItem('LoginStatus', '0');
        sessionStorage.setItem('LoginID', JSON.stringify(null));
        sessionStorage.setItem('LoginSchoolNo', JSON.stringify(null));
        sessionStorage.setItem('LoginStudent', JSON.stringify(null));
        sessionStorage.setItem('LearnRec',"");
        sessionStorage.setItem('LoginTeacher', JSON.stringify(null));
        sessionStorage.setItem('LearnRecCurr', "");
     
        if((sessionStorage.getItem("BindMail")!==null) || (sessionStorage.getItem("BindMail")!==""))
        {
            setBindMail(sessionStorage.getItem("BindMail")!);    
        }
    },[])
   return (
        <>
            <div style={{backgroundColor:'#FCF9F3'}}>
                <nav className="navbar navbar-expand-lg py-3" style={{backgroundColor:'#FCF9F3'}}>
                <div className="container align-items-center">
                    <div className="navbar-translate">
                    <a className="navbar-brand logo" href="/" rel="tooltip" title="數位讀寫網" data-placement="bottom">數位讀寫網</a>
                    </div>
                </div>
                </nav>
            </div>
            <div className="py-5">
                <div className="container" style={{maxWidth: '870px'}}>
                    <div className="row" style={{ borderRadius:'8px',boxShadow:'0px 0px 10px 5px #d7d7d7'}}>
                        <div className="col-12 col-md-6 order-2 order-md-1 bg-primary logreg px-4">
                            <h4 className="py-3 text-center text-white fw-bolder">註冊說明</h4>
                
                            <div className="text-white">
                                <div className="text-start my-4">
                                    <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>1. 使用本網站，請了解和遵守<a href="#" onClick={(e) => { e.preventDefault(); setOpenObjectModal(true); }}>隱私權及資訊安全政策</a></div>
                                    <div className="ms-3 mb-2" style={{textIndent:'-1em'}}>2. 沒有註冊，也可以進入讀寫練習(PP&DP)。有註冊的同學，可以保留和查詢作答記錄。</div>
                                </div>
                            </div>    
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2 font-weight-bold bg-white login px-4">
                            <h4 className="py-3 text-center text-primary fw-bolder">登入</h4>                    
                            <div className="tab-content mt-4">
                                <div className="text-center tab-pane fade show active" id="loginpp" role="tabpanel"
                                aria-labelledby="loginpp-tab">
                                <div className="mb-4 text-gray fw-normal text-sm">PP 基礎讀寫 / DP 深度讀寫 練習登入</div>
                                    <div>帳號 或 Email</div>
                                    <input type="text" className="form-control mb-3" placeholder="請輸入帳號 / Email" onChange={handleAccInp}/>
                                    <div>密碼</div>
                                    <input type="password" className="form-control mb-3" placeholder="請輸入密碼" onChange={handlePwdInp}/>
                                    <button className="btn btn-round btn-primary my-2 text-nowrap" style={{ marginRight: '10px' }} onClick={()=>chkLogin()} >登入</button>
                                    <hr className="hr1 my-4"/>
                                    <div>
                                        <button className="btn btn-round btn-primary my-2 text-nowrap" style={{ marginRight: '20px' }} onClick={()=>handleLink("/newteacher")}>老師註冊申請</button>
                                        <button className="btn btn-round btn-primary my-2 text-nowrap" style={{ marginRight: '20px' }} onClick={()=>handleLink("/newstudent")}>學生註冊申請</button>

                                    </div>
                                    <hr className="hr1 my-4"/>
                                    <div className="">
                                        <button title="以 Google 帳號登入" className="btn btn-round btn-outline-primary text-nowrap" onClick={()=>redirGoogle()}>
                                        <img src="./assets/img/gicon.png" alt="" className="me-2" style={{width:'20px'}}/>Google</button>
                                        <button title="新北市親師生平台帳號登入"
                                        className="btn btn-round btn-outline-primary text-nowrap ms-3" onClick={()=>redirTParty()}>新北市親師生平台</button>                                        
                                    </div>
                                </div>
                            </div>    

                        </div>
                    </div>
                </div>
            </div>

            <BSModal
                title={'提醒您'}
                isOpen={openModal}
                setOpenModal={setOpenModal}                
            >
                {errMsg}
            </BSModal>
            <ObjectModal 
                title={'隱私權及資訊安全政策'}
                isOpen={openObjectModal}
                setOpenModal={setOpenObjectModal}
                objectURL={"./imageDoc/數位讀寫網站-隱私權及資訊安全政策.pdf"}
            />
            <FooterPage/> 
        </>
    );
}

export default LoginPage;

function getCtrls(arg0: string) {
    throw new Error('Function not implemented.');
}
