
import React, { useEffect, useState } from 'react';

export  interface Istep{

  currentStep : string
}

const StepGuide: React.FunctionComponent<Istep>= ({currentStep}) => {


  const steps: string[] = ["承辦人主頁", "指導老師資料", "小隊基本資料","報名完成確認"];

  // TypeScript 內的樣式物件
  const styles: { [key: string]: (isActive?: boolean) => React.CSSProperties } = {
    stepGuide: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Arial, sans-serif",
    }),
    step: () => ({
      textAlign: "center",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }),
    circle: (isActive = false) => ({
      width: "30px",
      height: "30px",
      backgroundColor: isActive ? "#BE3144" : "#006400", // 🔥 當前步驟橘色，其他綠色
      color: "white",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      fontSize: "14px",
      marginBottom: "5px",
    }),
    label: (isActive = false) => ({
      fontSize: "12px",
      color: isActive ? "#BE3144" : "#006400", // 🔥 當前步驟標題變橘色
      fontWeight: isActive ? "bold" : "normal",
    }),
    line: () => ({
      width: "40px",
      height: "2px",
      backgroundColor: "#006400",
      margin: "0 10px",
    }),
  };

  return (
    <div style={styles.stepGuide()}>

      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div style={styles.step()}>
            <div style={styles.circle(step === currentStep)}>{index + 1}</div>
            <div style={styles.label(step === currentStep)}>{step}</div>
          </div>
          {index < steps.length - 1 && <div style={styles.line()}></div>}
        </React.Fragment>
      ))}
    </div>
  );
 };
  
  export default StepGuide;