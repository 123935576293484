import React, { useContext, useEffect, useState } from 'react';
import { handleLink } from '../../../modules/utils';
import { getAuthData, logOut } from '../tool/helper';
import { crtlInfo } from '../../../interfaces/EpModal';
import { MyContext } from '../../home';
interface IPropBar{

    crtlInfoList? :crtlInfo[] |undefined

}

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const ApplyFunctionBar: React.FunctionComponent<IPropBar> = () => {
    useEffect(() => {
        

        getCtrls(sessionStorage.getItem('role') || 'guest');
    }, []);

    const getCtrls = async (control: string) => {
        let ctrlInfo = await getAuthData(control);
        context?.setCtrlInfoList(ctrlInfo);
    };
    
    const context = useContext(MyContext);
    const getCtrl = (CtrlName: string) => {
        let result;
        if(context?.crtlInfoList?.length){
            result = context?.crtlInfoList?.find((x) => {
                return x.Control.trim() === CtrlName.trim();
            });
        }
        context?.setCurrentRole(result)
 
        return result;
    };

    const link = (ctrl: string ,route:string) => {
        if (getCtrl(ctrl)) {
            handleLink(route);
        } else {

        }
    };

    const disableStyle ={
        color:'gray',
        background :'#dddddd',
        cursor: 'not-allowed'

    }
    return (
        <>
            <div className="text-center text-lg-end exclude">
                <a
                    href={getCtrl('承辦人主頁') ? '/epApplyHome' : undefined}
                    onClick={() => {
                        link('承辦人主頁' ,'/epApplyHome');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 "
                    style={getCtrl('承辦人主頁') ?{}:disableStyle }
                    type="button"
                >
                    承辦人主頁
                </a>

                <a
                    href={getCtrl('指導老師資料') ? '/epApplyTeacher' : undefined}
                    onClick={() => {
                        link('指導老師資料','/epApplyTeacher');
                    }}
                    style={getCtrl('指導老師資料') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    指導老師資料 
                </a>
              
                <a
                    href={getCtrl('小隊基本資料') ? '/epApplyGroup' : undefined}
                    onClick={() => {
                        link('小隊基本資料','/epApplyGroup');
                    }}
                    style={getCtrl('小隊基本資料') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    小隊基本資料
                </a>
                <a
                    href={'/epApplyCompleteCheck' }
                    onClick={() => {
                        link('報名完成確認' ,'/epApplyCompleteCheck');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 "
                    // style={getCtrl('報名完成確認') ?{}:disableStyle }
                    type="button"
                >
                    報名完成確認
                </a>
                <a
                    href="/epApplyExplore"
                    onClick={() => {
                        link('專題探究','/epApplyExplore');
                    }}
                    style={getCtrl('專題探究') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    專題探究
                </a>
                {/* <a
                    style={disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap　me-2 me-sm-3    "
                    type="button"
                >
                    登入紀錄
                </a> */}
                
                <a
                    onClick={() => {
                        logOut();
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
            </div>
        </>
    );
};
export default ApplyFunctionBar;
