import React, { useEffect, useState } from 'react';



import { Export2Word, IsubTopicForModal, ReacherArPictureName_after, alertEx, currentClass, currentClassForBtn, downloadImage, formatDate, geIndexBytOpinionCode, getClassNameForShow, getFileNameString, getLoginInfo, getModalConfigs, replaceNewline, sourceType, validate } from '../tool/helper';
import { IOpinion, IsubTopic, Param11OrPost11, TeacherGroupInfo, crtlInfo, subTopicParam } from '../../../interfaces/EpModal';
import { StudentDisplay } from '../epStudentHome';
import { createOpinion, deleteOpinionByUID, deleteSubTopicByGroupNoSubNo, getOpinionByGroupOpIdx, paramsCreateOpinionSubTopic, updateOpinionByGroupOpIdxUID, updateSubTopicByUid } from '../../../services/EP/OpinionService';
import {
    Action as action,
    IOpinionForModal,
    formatDateTime,
    OpinCode,

} from '../tool/helper';
import { getDrGroupByStuNoClass, getOpinionByStuNo } from '../../../services/EP/EpApplyStudentService';
import { Modal } from 'react-bootstrap';
import EditModal from './EditModal';
import { getSubTopicByGroupNo } from '../../../services/EP/EpApplyExploreService';
import { FileUploader } from '../../../components/fileUploader';
import { checkFileExists } from '../../../services/DrFileService';
import Loding from './loding';
interface IProp {
    studentInfo: StudentDisplay | undefined
    teacherInfo?: string
    /** 登入者狀態 */
    setStudentInfo: (studentDisplay: StudentDisplay) => void;
    isFeedback: 0 | 1
    opinion: IOpinion | undefined
    role: string
    groupNo: string | undefined
    TeacherInfo?: string
    selectGroupInfo: TeacherGroupInfo | undefined
    onFinish: () => void
}

// 1-1 提出探究題目 (回覆後)
const OpinionStageAfter22: React.FunctionComponent<IProp> = (props) => {

    const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();
    const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });
    const [isReload, setIsReload] = useState<boolean>(false);
    const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState<any>();
    const [subTopicAfterFeedbackList, setSubTopicAfterFeedbackList] = useState<IsubTopic[]>();
    const [isUploading, setIsUploading] = useState(false)

    const [isPictureExist, setIsPictureExist] = useState(false)


    useEffect(() => {
        getIsFileExist()


    }, [props.opinion, currentClass, isReload])


    const getIsFileExist = async () => {

        const rsp = await checkFileExists(`/uploads/upload${currentClass}/${props.opinion?.GroupNo}`, ReacherArPictureName_after, "")
        if (rsp == '檔案已存在.') {
            setIsPictureExist(true)
        } else {
            // alert("不存在")

            setIsPictureExist(false)
        }
    }
    useEffect(() => {
        fetchDataSubTopic()
        // setIsReload(false)


    }, [isReload, props.studentInfo, props.opinion])




    const fetchDataSubTopic = async () => {
        if (props.studentInfo?.GroupNo) {
            let subTopicbefore = (await getSubTopicByGroupNo(props.isFeedback, props.studentInfo?.GroupNo)) as IsubTopic[];
            setSubTopicAfterFeedbackList(subTopicbefore);
        } else if (props.groupNo) {
            let subTopicbefore = (await getSubTopicByGroupNo(props.isFeedback, props.groupNo)) as IsubTopic[];
            setSubTopicAfterFeedbackList(subTopicbefore);

        }
    };

    // 畫面顯示跟 set fromData 
    const openEditModal = async (opinCode: OpinCode, action: action, param11?: Param11OrPost11, subTopic?: IsubTopic, enable?: boolean) => {
        alertEx("openEditModal")
        if (enable) {
            return
        }
        //取得題目設定檔
        let modalConfig = getModalConfigs(opinCode, action, 1);
        let params = {
            UID: undefined,
            ParamType: modalConfig?.sourceType,
            GroupNo: props.groupNo,
            MemberNo: props.studentInfo?.StuNo,
            DataA: '',
            DataB: '',
            DataC: ''
        } as paramsCreateOpinionSubTopic;

        setFormData(params);
        /**  彈出視窗設定檔*/
        if (props.groupNo) {
            if (action === '新增') {
                params = {
                    action: '新增',
                    UID: undefined,
                    ParamType: modalConfig?.sourceType,
                    GroupNo: props.groupNo,
                    MemberNo: props.studentInfo?.StuNo || '',
                    DataA: '',
                    DataB: '',
                    DataC: ''
                } as paramsCreateOpinionSubTopic;

                setFormData(params);
                //需要有設定檔 跟 修改對象 ID
            } else if (action === '修改') {
                // fectchData todo
                if (modalConfig) {
                    //如果沒有 傳進來的物件
                    if (param11 && !subTopic) {

                        params = {
                            action: '修改',
                            UID: param11.OpinionID,
                            ParamType: modalConfig.sourceType,
                            GroupNo: props.groupNo,
                            MemberNo: props.studentInfo?.StuNo || '',
                            DataA: param11.DataA,
                            DataB: param11.DataB,
                            DataC: '',
                            termsStringList: param11.DataB.split('、')
                        } as paramsCreateOpinionSubTopic;
                        setFormData(params);
                    } else if (!param11 && subTopic) {
                        // 沒有物件
                        try {
                            params = {
                                action: '修改',
                                UID: subTopic.uid,
                                ParamType: modalConfig.sourceType,
                                GroupNo: props.groupNo,
                                MemberNo: props.studentInfo?.StuNo,
                                SubNo: subTopic.SubNo + '',
                                SubName: subTopic.SubName,
                                Description: subTopic.Description
                            } as paramsCreateOpinionSubTopic;

                            setFormData(params);
                        } catch (ex) {
                            alert('ex:' + JSON.stringify(ex));
                        }
                    }
                }
            } else if (action === '新增或修改') {

                if (modalConfig) {
                    // 有moaal 設定檔
                    if (!param11 && !subTopic) {
                        // topic
                        try {
                            let optionCode = geIndexBytOpinionCode(opinCode);
                            // 確認原本有沒有資料

                            let rsp = (await getOpinionByGroupOpIdx(modalConfig.isfeedback, optionCode, props.groupNo)) as any[];
                            if (rsp.length > 0) {
                                if (props.role == 'DrStudent' && props.studentInfo) {
                                    params = {
                                        action: '修改',
                                        UID: rsp[0].OpinionID || rsp[0].uid,
                                        GroupNo: props.groupNo,
                                        MemberNo: props.studentInfo?.StuNo,
                                        DataA: rsp[0].DataA || null,
                                        DataB: rsp[0].DataB || null,
                                        DataC: rsp[0].DataC || null
                                    } as paramsCreateOpinionSubTopic;

                                } else if (props.role == 'DrTeacher') {
                                    params = {
                                        action: '修改',
                                        UID: rsp[0].OpinionID || rsp[0].uid,
                                        ParamType: modalConfig.sourceType,
                                        GroupNo: props.groupNo,
                                        MemberNo: props.studentInfo?.StuNo,
                                        DataA: rsp[0].DataA || null,
                                        DataB: rsp[0].DataB || null,
                                        DataC: rsp[0].DataC || null
                                    } as paramsCreateOpinionSubTopic;

                                }
                                // 原本有資料 設定修改

                                setFormData(params);
                            } else {
                                // 沒有資料就設定  新增
                                if (props.role == 'DrStudent' && props.studentInfo) {
                                    params = {
                                        action: '新增',
                                        UID: undefined,
                                        ParamType: modalConfig?.sourceType,
                                        GroupNo: props.groupNo,
                                        MemberNo: props.studentInfo?.StuNo,
                                        DataA: '',
                                        DataB: '',
                                        DataC: ''
                                    } as paramsCreateOpinionSubTopic;


                                } else if (props.role == 'DrTeacher') {
                                    params = {
                                        action: '新增',
                                        UID: undefined,
                                        ParamType: modalConfig?.sourceType,
                                        GroupNo: props.groupNo,
                                        MemberNo: '',
                                        DataA: '',
                                        DataB: '',
                                        DataC: ''
                                    } as paramsCreateOpinionSubTopic;


                                }

                                setFormData(params);
                            }
                        } catch (ex) {
                            alert('ex:' + JSON.stringify(ex));
                        }
                    } else if (!param11 && subTopic) {
                    } else if (param11 && !subTopic) {
                        //opinion 一對一
                    }
                }
            } else if (action === '刪除') {
            }
        }

        if (getModalConfigs(opinCode, action, 1)) {
            setCurrentShowModal(getModalConfigs(opinCode, action, 1));
        }
        setIsAddRsearchModalOpen(true);
    };


    const deleteItem = async (deleteType: sourceType, subTopic?: IsubTopic, param11?: Param11OrPost11, enable?: boolean) => {
        if (enable) {
            return
        }

        if (window.confirm('確定刪除?')) {
            try {
                if (deleteType === 'subTopic' && subTopic) {
                    if (subTopic.GroupNo && subTopic.uid) {
                        await deleteSubTopicByGroupNoSubNo(1, subTopic.GroupNo, subTopic.SubNo);
                    }
                } else if (deleteType === 'opinion' && param11) {
                    await deleteOpinionByUID(1, param11.OpinionID);
                }
                alertEx('刪除成功!');
                setIsReload(!isReload);
            } catch (ex) {
                console.log(ex);
            }
        }
    };
    const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
    /** */
    // const handleDownload = async () => {
    //     try {
    //         // 使用給定的文件路徑進行下載
    //         setIsDownloaded(true);
    //         const response = await fetch('/assets/img/ep/file_upload.png');
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);

    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = 'file_upload.png'; // 設置下載的文件名
    //         document.body.appendChild(a);
    //         a.click();
    //         a.remove();

    //         // 等待文件下載完成後

    //     } catch (error) {
    //         console.error('Download error:', error);
    //     } finally {
    //         setIsDownloaded(false);
    //     }

    // };

    return (
        <>

            <div className="collapse" id="level2">
                <div className="bd-callout d-flex flex-row flex-wrap justify-content-between align-items-center px-4 py-3 mt-0 mb-3">
                    <div>
                        <div>東西太大咬不下時，我們會把它切成小塊好入口。</div>
                        <div>專題探究也一樣，要把題目分成幾個子題，找資料才方便。</div>
                        <div>每個子題可能還包括一些重要的元素，試著把架構畫出來！</div>
                        <div>有了架構圖，探究時就不會迷路啦！</div>
                    </div>
                    <div>
                        <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach2">
                            怎樣規劃探究架構
                        </div>
                        <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('stage2', '階段2', getFileNameString(props.selectGroupInfo, props.studentInfo))}>
                            匯出檔案
                        </div>
                    </div>
                </div>
                <div className="card p-4 pt-3" id="stage2">
                    <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                    <ul>
                        <li>把你們探究的題目再細分成若干個子題。</li>
                        <li>建議細分成3~4個子題。</li>
                        <li>這裡全體隊員均可編輯。</li>
                    </ul>

                    <div className="d-flex flex-wrap align-items-end">
                        <div className="fw-bolder text-second pt-3 pb-2">
                            本隊探究題目：
                            <span className="text-primary">{props.opinion?.Post12_DataA}</span>
                        </div>
                        {props.role === 'DrStudent' ? (<>
                            <div
                                className="btn text-second bg-second mb-0 pointer ms-auto"
                                data-bs-toggle="modal"
                                data-bs-target="#editModal2-1"
                                onClick={() => openEditModal('新增探究子題', '新增')}
                            >
                                <span className="material-icons-outlined align-middle">add</span>
                                <span>新增探究子題</span>
                            </div>
                        </>) : (
                            <>
                            </>
                        )


                        }
                        {


                        }

                    </div>

                    <div className="table-responsive pt-3">
                        <table className="text-sm table table-striped">
                            <thead>
                                <tr className="font-weight-bolder text-second" style={{ backgroundColor: '#d9dce6' }}>
                                    <th className="p-2">#</th>
                                    <th className="p-2 text-nowrap">子題名稱</th>
                                    <th className="p-2 text-nowrap">負責隊員</th>
                                    <th className="p-2 text-nowrap">日期</th>
                                    <th className="p-2 text-nowrap print-remove">功能</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {JSON.stringify(subTopicBeforeFeedbackList)} */}
                                {subTopicAfterFeedbackList?.map((item) => (
                                    <tr>
                                        <td>{item.SubNo}</td>
                                        <td>{item.SubName}</td>
                                        <td className="text-nowrap">{item.Description || ''}</td>
                                        <td>{formatDate(item.UpdateDate)}</td>
                                        <td className="text-nowrap print-remove">
                                            <span className="material-icons text-dark pointer me-3" onClick={() => deleteItem('subTopic', item)} title="刪除">
                                                delete_outline
                                            </span>
                                            <span
                                                className="material-icons text-dark pointer"
                                                data-bs-toggle="modal"
                                                data-bs-target="#editModal2-1"
                                                title="編輯"
                                                onClick={() => openEditModal('修改探究子題', '修改', undefined, item)}
                                            >
                                                edit
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>



                    <hr className="horizontal dark" />
                    <h5 className="fw-bolder pt-3">2-2 繪製探究架構</h5>
                    <ul>
                        <li>使用你熟悉的軟體繪製，如 Powerpoint、Xmind、Cacoo等。手繪後拍照上傳亦可，要清晰並轉正。</li>
                        <li>存成副檔名為jpg的檔案後上傳。</li>
                        <li>重傳檔案後若沒有立即更新圖片，請重整網頁(Ctrl+F5)，或清除瀏覽器歷史記錄的快取圖片。</li>
                        <li>這裡全體隊員均可編輯。</li>
                    </ul>
                    <div className="rounded-3 mx-auto p-3 imgborder text-center">
                        <div className="d-flex justify-content-center align-items-start">

                            {isPictureExist ? (
                                <div>

                                    <img
                                        className="print-remove imgs"
                                        src={`/uploads/upload${currentClass}/${props.opinion?.GroupNo}/${ReacherArPictureName_after}?v=${new Date().getSeconds()}`}
                                        alt=""
                                        width="500"

                                    />
                                      <div>
                                      <button
                                        onClick={() => downloadImage(`/uploads/upload${currentClass}/${props.opinion?.GroupNo}/${ReacherArPictureName_after}`, '研究架構.png')}
                                        type="button"
                                        className="btn btn-secondary mt-3"
                                        data-bs-dismiss="modal"
                                    >
                                        下載架構圖
                                    </button>
                                      </div>  
                              
                                </div>
                            ) : (<img className="print-remove" src="/assets/img/ep/file_upload.png" alt="" />)}

                            {/* {`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName}`} */}
                        </div>
                        {/* <button onClick={handleDownload}>Download File</button> */}
                        {isDownloaded && <div><Loding /></div>}
                    </div>
                    <div
                        //  className="mx-auto my-3"  
                        className={getClassNameForShow(['DrStudent'], props.role, "mx-auto my-3")}>
                        {/* /upload113/FD001-01" */}

                        <FileUploader

                            fileType="image/jpeg,image/jpg"
                            uploaderDir={`upload${currentClass}/${props.opinion?.GroupNo}`}
                            buttonStr="上傳檔案"
                            setIsReload={setIsReload} isReload={isReload}
                            setIsUploading={setIsUploading} isUploading={isUploading}
                            NewFileName={ReacherArPictureName_after}
                        />
                    </div>

                    <hr className="horizontal dark" />
                    <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                    <ul>
                        <li>請全體隊員共同回顧在「階段2」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                        <li>這裡全體隊員均可編輯。</li>
                    </ul>
                    <div className="concard p-3">
                        <span
                            // className="material-icons float-end pointer"
                            className={getClassNameForShow(['DrStudent'], props.role, "material-icons float-end pointer")}
                            data-bs-toggle="modal"
                            data-bs-target="#editModal2-3"
                            title="編輯"
                            onClick={() => openEditModal('2_4困難、解決與新學習', '新增或修改')}
                        >
                            edit
                        </span>
                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段2」遇到的困難：</div>
                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post24_DataA || '') }}></div>
                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post24_DataB || '') }}></div>
                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post24_DataC || '') }}></div>
                        <div className="pt-3 pb-2">
                            <span className="fw-bolder text-second">日期：</span>
                            <span>{formatDateTime(props.opinion?.Post24_UpdateDate)}</span>
                        </div>
                    </div>
                    <hr className="horizontal dark" />
                    <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                    <div className="concard p-3">
                        <span
                            // className="material-icons float-end pointer"
                            className={getClassNameForShow(['DrTeacher'], props.role, "material-icons float-end pointer")}
                            data-bs-toggle="modal"
                            data-bs-target="#editModal2-3"
                            title="編輯"
                            onClick={() => openEditModal('2_9指導老師留言', '新增或修改')}
                        >
                            edit
                        </span>
                        <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post29_DataA || '') }}>{/* 學校裡的新住民子女學生人數大約在70人上下 */}</div>
                        <div className="pt-3 pb-2">
                            <span className="fw-bolder text-second">日期：</span>
                            <span>{formatDateTime(props.opinion?.Post29_UpdateDate)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div>



                <EditModal
                    groupNo={props.groupNo}
                    isAddRsearchModalOpen={isAddRsearchModalOpen}
                    setIsAddRsearchModalOpen={setIsAddRsearchModalOpen}
                    currentShowModal={currentShowModal}
                    studentInfo={props.studentInfo}
                    setStudentInfo={props.setStudentInfo}
                    formData={formData}
                    setFormData={setFormData}
                    onFinish={() => { setIsReload(true); props.onFinish() }}
                />

            </div>
        </>
    );
};
export default OpinionStageAfter22;


