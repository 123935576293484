import React, { useContext, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import TopNavPage1 from '../topNav1';
import ApplyFunctionBar from './component/applyFunctionBar';
import {  currentClass, disableElement, formatDate, getLoginInfo, validateSubmit } from './tool/helper';
import {
    createDrGroupByYrApplyNo,
    createDrStudentByGroup,
    createDrTeaGroupByTeaNoGroup,
    delDrStudentByUid,
    deleteDrGroupByYrGroupNo,
    deleteDrTeaGroupByGroupNo,
    getDrGroupPrimeByYrApplyNo,
    getDrStudentByGroup,
    getDrTeaGroupByGroupNo,
    updateDrGroupPrimeByYrGroupNo,
    updateDrStudentByNo
} from '../../services/EP/EPApplyGroupService';
import { IGroupTeacherInfo, IApplyFormData, IGroupInfo, IGroupStudent, IInsertApplyGroup, IUpdateApplyGroup, IUpdateApplyStudent, IInsertApplyStudent } from '../../interfaces/EpModal';
import { BSModal } from '../../components/modal/bsmodal';
import { Modal } from 'react-bootstrap';
import { getEPApplyByNo } from '../../services/EP/EpLoginService';
import DropdownSelect from './component/DropdownSelectProps';
import { MyContext, MyContextType } from '../home';
import StepGuide from './component/StepGuide';
import NoEntry from './tool/NoEntry';
import { useControlCheck } from './HOC/withControlCheck';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const EpApplyGroup: React.FunctionComponent<IPageProps> = (props) => {
    const pageName = "小隊基本資料"
    const [openErrModal, setOpenErrModal] = useState(false);
    const [groupDataList, setGroupDataList] = useState<IGroupInfo[]>([]);
    const [applyInfo, setApplyInfo] = useState<IApplyFormData>();
    /** 目前選得隊伍 */
    const [selectedGroup, setSelectedGroup] = useState<IGroupInfo>();
    /** 目前選擇的隊伍明細 */
    const [currentGroupStudentList, setCurrentGroupStudentList] = useState<IGroupStudent[]>([]);
    const [groupteacherList, setGroupTeacherList] = useState<IGroupTeacherInfo[]>([]);
    const [selectTeacher1, setSelectTeacher1] = useState<any>('');
    const [selectTeacher2, setSelectTeacher2] = useState<any>('');
    const [isReload, setIsReload] = useState<boolean>();
    const [errMsg, setErrMsg] = useState('');
    const [btndisable, setBtndisable] = useState(false);
    const [errInfo, seterrInfo] = useState('');

    const [formDataGroup, setFormDataGroup] = useState<IGroupInfo>({
        ApplyNo: '',
        Class: '',
        CompLearn: '',
        FlagOk: '',
        FlagRevOK: '',
        GroupNo: '',
        Groupname: '',
        PptDate: '',
        Remark: '',
        SchoolFull: '',
        Schoolname: '',
        Type: '',
        UploadDate: '',
        uid: '',
        teacher1: '',
        teacher2: ''
    });

    const [formDataStudent, setFormDataStudent] = useState<IGroupStudent>({
        mode: '',
        Compare: '',
        GroupNo: '',
        Password: '',
        Remark: '',
        StuGrade: '',
        StuName: '',
        StuNo: '',
        uid: ''
    });
    const [isBCWork, setisBCWork] = useState<boolean>(false);
    const [isOpenEdit, setIsOpenEditGroup] = useState<boolean>(false);
    const [isOpenEditStudent, setIsOpenEditStudent] = useState<boolean>(false);
    const [mode, setMode] = useState<'新增' | '編輯' | ''>('');
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();

    let context = useContext(MyContext);
    useEffect(() => {
        checkControl(context, sessionStorage.getItem('role') || 'guest', '小隊基本資料');
    }, []);
    const checkControl = async (context_: MyContextType | undefined, role_: string, homePage_: string) => {
        if (context_?.crtlInfoList?.find((x) => x.Control.trim() === homePage_)?.Action.trim() === 'view') {
            disableElement();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            let groupList = await fetchDataGroup();
            setSelectedGroup(groupList[0]);
        };
        getEPApplyByLoginID(sessionStorage.getItem('LoginID') + '');
        fetchData();
        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }
    }, [isReload]);

    useEffect(() => {
        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }
       

    }, [selectedGroup]);


    useEffect(() => {
        if (currentGroupStudentList.length<3) {
            seterrInfo(`一個小隊3~4位，目前只有${currentGroupStudentList.length}位成員`)
        }else{

            seterrInfo(``)
        }
       

    }, [currentGroupStudentList]);

    /**取得學生資料 */
    const fetchDataGroupStudent = async (groupNo: string) => {
        let groupStudentList = (await getDrStudentByGroup(groupNo)) as IGroupStudent[];
        setCurrentGroupStudentList(groupStudentList);
     
        // 取小隊指導老師
        let groupTeachers = (await getDrTeaGroupByGroupNo(groupNo)) as IGroupTeacherInfo[];
        setGroupTeacherList(groupTeachers);
    };

    /**  取資料 */
    const fetchDataGroup = async (): Promise<IGroupInfo[]> => {
        let currentLoginInfo = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo);
        let GroupDataList = (await getDrGroupPrimeByYrApplyNo(currentClass, currentLoginInfo.LoginID)) as IGroupInfo[];
        setGroupDataList(GroupDataList);
        return GroupDataList;
    };

    /** 取得登入者基本資訊 (申請者)*/
    const getEPApplyByLoginID = async (loginID: string) => {
        let rsp = await getEPApplyByNo(loginID);
        // alert(JSON.stringify(rsp))
        setApplyInfo(rsp[0] as IApplyFormData);
    };

    const validate = (target: '學生' | '小隊') => {
        let errMsg = '';
        if (target === '小隊') {
            let errorMsgs = validateSubmit();
            if (currentGroupStudentList.length === 4) {
                errorMsgs.push('小隊人數最多4人!');
                // alert();
                return ;
            }
            if (!selectTeacher1) {
                errorMsgs.push('指導老師至少指派一位');
            }
            if (errorMsgs.length) {
                errMsg = errorMsgs.join('\n'); // 使用换行符 '\n' 连接数组的元素
            }
            return errMsg;
        }

        if (target === '學生') {
            let errorMsgs = validateSubmit();
            if (errorMsgs.length) {
                errMsg = errorMsgs.join('\n'); // 使用换行符 '\n' 连接数组的元素
            }
            return errMsg;
        }
    };
    /** 儲存小隊資料 */
    const insertOrUpdateGroupInfo = async (action: '新增' | '編輯' | '') => {
        setBtndisable(true);
        try {
            if (action === '新增') {
                const errMsg = validate('小隊');
                if (errMsg) {
                    setErrMsg(errMsg);
                    setOpenErrModal(true);
                    setBtndisable(false);
                    return;
                }
                if (applyInfo?.ApplyNo) {
                    let params: IInsertApplyGroup = {
                        groupName: formDataGroup.Groupname,
                        schoolName: applyInfo?.SchoolName,
                        schoolFull: applyInfo?.SchoolFullName,
                        type: applyInfo?.Type,
                        remark: formDataGroup.Remark,
                        compLearn: formDataGroup.CompLearn
                    } as IInsertApplyGroup;
                    debugger
                    let rsp = (await createDrGroupByYrApplyNo(params, currentClass, applyInfo?.ApplyNo)) as any;
                    if (rsp?.GroupNo) {
                        //開始新增老師
                        if (selectTeacher1) {
                            const param = { groupno: rsp.GroupNo, teano: selectTeacher1.TeaNo };
                            // alert(JSON.stringify(param))
                            await createDrTeaGroupByTeaNoGroup(param);
                        }
                        if (selectTeacher2) {
                            const param = { groupno: rsp.GroupNo, teano: selectTeacher2.TeaNo };
                            await createDrTeaGroupByTeaNoGroup(param);
                        }
                    }

                    alert('小隊新增成功!');
                    setIsOpenEditGroup(false);
                    setIsReload(!isReload);
                    setBtndisable(false);
                    setSelectTeacher1('');
                    setSelectTeacher2('');
                }
            } else if (action === '編輯') {
                const errMsg = validate('小隊');
                if (errMsg) {
                    setErrMsg(errMsg);
                    setOpenErrModal(true);
                    setBtndisable(false);
                    return;
                }
                let params: IUpdateApplyGroup = {
                    groupName: formDataGroup.Groupname?.trim() || '',
                    schoolName: applyInfo?.SchoolName?.trim() || '',
                    schoolFull: applyInfo?.SchoolFullName?.trim() || '',
                    flagOK: formDataGroup.FlagOk ? 1 : 0, //報名審核 傳入1或0
                    flagRevOK: formDataGroup.FlagRevOK ? 1 : 0, //五階段完成 傳入1或0
                    uploadDate: '', //上傳成果報告日期
                    pptDate: formDataGroup.PptDate?.trim() || '', //上傳PPT日期
                    compLearn: formDataGroup.CompLearn?.trim() || '', //參加競賽，學習不參加競賽，取消報名
                    type: '',
                    remark: formDataGroup?.Remark?.trim() || ''
                } as IUpdateApplyGroup;

                if (applyInfo?.ApplyNo) {
                    try {
                        await updateDrGroupPrimeByYrGroupNo(params, currentClass, formDataGroup.GroupNo);
                        // 更新老師
                        await deleteDrTeaGroupByGroupNo(formDataGroup.GroupNo);
                        if (formDataGroup.GroupNo) {
                            //開始新增老師
                            if (selectTeacher1) {
                                const param = { groupno: formDataGroup.GroupNo, teano: selectTeacher1.TeaNo };
                                await createDrTeaGroupByTeaNoGroup(param);
                            }
                            if (selectTeacher2) {
                                const param = { groupno: formDataGroup.GroupNo, teano: selectTeacher2.TeaNo };
                                await createDrTeaGroupByTeaNoGroup(param);
                            }
                        }
                    } catch (ex) {
                        console.log('ex', ex);
                    }
                    closeModal();
                    setIsReload(!isReload);
                }
            }
        } catch (ex) {
            alert('儲存小隊發生錯誤 :' + JSON.stringify(ex));
        } finally {
            setBtndisable(false);
        }
    };

    const closeModal = () => {
        setIsOpenEditGroup(false);
    };

    /**儲存學生資料*/
    const insertOrUpdateStudentInfo = async (action: '新增' | '編輯' | '') => {

        setBtndisable(true);
        setisBCWork(true)
        if (action === '編輯') {
            const errMsg = validate('學生');
            if (errMsg) {
                setErrMsg(errMsg);
                setOpenErrModal(true);
                return;
            }

            if (!errMsg) {
                try {
                    let param: IUpdateApplyStudent = {
                        groupno: formDataStudent?.GroupNo.trim(),
                        stuname: formDataStudent.StuName.trim(),
                        stugrade: formDataStudent.StuGrade.trim(),
                        password: formDataStudent.Password.trim(),
                        compare: formDataStudent.Compare?.trim(),
                        remark: formDataStudent.Remark?.trim() || ''
                    } as IUpdateApplyStudent;

                    await updateDrStudentByNo(param, formDataStudent.StuNo);

                    setIsOpenEditStudent(false);

                    setSelectedGroup({ ...selectedGroup } as IGroupInfo);
                    setBtndisable(false);
                } catch (ex) {
                    alert('編輯學生發生錯誤' + JSON.stringify(ex));
                }
            }
        } else if (action === '新增') {
            const errMsg = validate('學生');
            if (errMsg) {
                setErrMsg(errMsg);
                setOpenErrModal(true);
                return;
            }

            if (!errMsg) {
                try {
                    let param = {
                        StuName: formDataStudent.StuName,
                        StuGrade: formDataStudent.StuGrade.trim(),
                        Password: formDataStudent.Password.trim(),
                        Compare: formDataStudent.Compare?.trim(),
                        Remark: formDataStudent.Remark?.trim()
                    } as IInsertApplyStudent;
                    await createDrStudentByGroup(param, formDataStudent.GroupNo);

                    setIsOpenEditStudent(false);
                    setSelectedGroup({ ...selectedGroup } as IGroupInfo);
                    // setIsReload(!isReload);
                } catch (ex) {
                    alert('新增學生發生錯誤' + JSON.stringify(ex));
                }
            }
        }
    };

    /** 當設定畫面有改變時*/
    const setFormDataOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr: string) => {
        setFormDataGroup({ ...formDataGroup, [attr]: e.target.value } as IGroupInfo);
    };

    const setFormDataStudentOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr: string) => {
        setFormDataStudent({ ...formDataStudent, [attr]: e.target.value } as IGroupStudent);
    };

    /**  */
    useEffect(() => {
        groupDataList.forEach((group: IGroupInfo) => {});
    }, [groupDataList]);
    /** 刪除小隊資料 */
    const deleteGroup = async (targetGroup: IGroupInfo) => {
        const isdeleted = window.confirm('確定刪除小隊？');
        if (isdeleted) {
            try {
                await deleteDrGroupByYrGroupNo(currentClass, targetGroup.GroupNo);
                alert('刪除成功!');
                setIsReload(!isReload);
            } catch (ex) {
                alert('刪除小隊發生錯誤!');
            }
        }
    };
    const deleteStudent = async (targetStudent: IGroupStudent) => {
        const isdeleted = window.confirm('確定刪除學生？');
        if (isdeleted && targetStudent.uid) {
            try {
                await delDrStudentByUid(targetStudent.uid);
                alert('刪除成功!');
                setIsReload(!isReload);
            } catch (ex) {
                alert('刪除學生發生錯誤!' + JSON.stringify(ex));
            }
        }
    };

    const openGroupEditModal = (mode: '新增' | '編輯' | '', group?: IGroupInfo) => {
        if (group && mode === '編輯') {
            setFormDataGroup(group);
        } else if (mode === '新增') {
            setFormDataGroup({
                ApplyNo: '',
                Class: '',
                CompLearn: '',
                FlagOk: '',
                FlagRevOK: '',
                GroupNo: '',
                Groupname: '',
                PptDate: '',
                Remark: '',
                SchoolFull: '',
                Schoolname: '',
                Type: '',
                UploadDate: '',
                uid: ''
            } as IGroupInfo);
        }
        setMode(mode);
        setIsOpenEditGroup(true);
        setSelectTeacher1('');
        setBtndisable(false);
    };

    const openStudentEditModal = (mode: '新增' | '編輯' | '', student?: IGroupStudent) => {
        // 確認目前小隊人數
        if (currentGroupStudentList.length === 4) {
            alert('小隊人數最多4人!');
            return;
        }

       

        if (student && mode === '編輯') {
            setFormDataStudent(student);
        } else if (mode === '新增') {
            setFormDataStudent({
                mode: mode,
                Compare: '',
                GroupNo: selectedGroup?.GroupNo?.trim(),
                Password: '',
                Remark: '',
                StuGrade: '',
                StuName: '',
                StuNo: '',
                uid: ''
            } as IGroupStudent);
        }
        setMode(mode);
        setIsOpenEditStudent(true);
        setBtndisable(false);
    };

    /** 打開 */
    const isView = useControlCheck('小隊基本資料');
    if (!isView) {
        return <NoEntry />;
    }
   

    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <ApplyFunctionBar crtlInfoList={context?.crtlInfoList} />
                    </div>
                    <StepGuide  currentStep={pageName}/>
                    <div className="text-center h5 mt-4">小隊基本資料</div>
                    <div className="text-muted fs-18 fw-bold mb-3">小隊基本資料增修說明</div>
                    <ol>
    <li>「小隊名稱」可使用中英文或數字（不可使用其他文字或 <code>&lt; &gt; : “ / \ | ？ * </code>  等符號，若要凸顯字詞建議使用「」單引號）。</li>
    <li>純中文隊名以 8 個字內為原則，英文或中英文數字混用隊名（含空白）不可超過 15 個字元。</li>
    <li>請將以上師生編號及密碼交給競賽小隊成員，並填寫 <a href="./imagedoc/參賽證明書.docx">「參賽聲明書」</a> 後，即完成報名。</li>
    <li>第 1~2 階段期間，可申請更換隊員或指導老師（皆以一次為限）。</li>
</ol>

                    <hr className="horizontal dark" />
                    <div className="d-flex flex-wrap mb-3 mt-4">
                        <div className="text-muted fs-18 fw-bold">
                            {applyInfo?.SchoolName} 承辦人: {applyInfo?.ApplyName} ({applyInfo?.ApplyNo})
                        </div>
                        <div className="btn text-second bg-second pointer mb-0 ms-auto" data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => openGroupEditModal('新增')}>
                            <span className="material-icons-outlined align-middle">add</span>
                            <span>新增小隊</span>
                        </div>
                    </div>
                    <div className="card p-4 pt-3">
                        <div className="table-responsive pt-3">
                            <table className="text-sm table table-striped mb-0">
                                <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                        <th className="p-2 text-nowrap">小隊編號</th>
                                        <th className="p-2 text-nowrap">小隊名稱</th>
                                        <th className="p-2 text-nowrap">競賽/學習</th>
                                        <th className="p-2 text-nowrap">報名審核OK</th>
                                        <th className="p-2 text-nowrap">完成成果報告</th>
                                        <th className="p-2 text-nowrap">備註</th>
                                        <th className="p-2 text-nowrap" style={{ width: '100px' }}>
                                            功能
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupDataList.map((group) => (
                                        <tr>
                                            <td>{group.GroupNo}</td>
                                            <td>{group.Groupname}</td>
                                            <td>{group.CompLearn}</td>
                                            <td>{group.FlagOk}</td>
                                            <td>
                                                {formatDate(group.UploadDate)}
                                            </td>

                                            <td>{group.Remark}</td>
                                            <td className="text-nowrap">
                                                <span className="material-icons text-dark pointer me-3 date-remove" onClick={() => deleteGroup(group)} title="刪除">
                                                    delete_outline
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        openGroupEditModal('編輯', group);
                                                    }}
                                                    className="material-icons text-dark pointer date-remove"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editModal"
                                                    title="編輯"
                                                >
                                                    edit
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr className="horizontal dark" />
                    <div className="d-flex flex-wrap mb-3 mt-4">
                        <div className="text-muted fs-18 fw-bold">管理小隊成員資料 (小隊成員3-4人)</div>
                        <div className="mx-auto">
                            <div id="sdgs" className="dropdown d-flex justify-content-center">
                                <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '200px' }}>
                                    {selectedGroup?.Groupname}
                                </button>
                                <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                    {groupDataList.map((group) => (
                                        <li onClick={() => setSelectedGroup(group)}>
                                            <div className="dropdown-item d-flex">{group.Groupname}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="flex-wrap mb-3 mt-4 justify-content-between">
                                <div className="text-second mt-3">
                                    指導老師：
                                    <span>
                                        {groupteacherList?.map((i, index) => (
                                            <>
                                                {i.TeaNo}
                                                {i.TeaName}
                                                {index !== groupteacherList.length - 1 && <span> / </span>}
                                            </>
                                        ))}
                                    </span>
                                </div>
                                <div className="text-second mt-3" >
                                
                                     </div>
                            </div>
                        </div>
                        <div className=' float-right"'>
                        <div className="btn text-second bg-second pointer mb-0 ms-auto ms-auto" data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => openStudentEditModal('新增')}>
                                <span className="material-icons-outlined align-middle">add</span>
                                <span>新增小隊成員</span>
                            </div>
                        </div>
                    </div>
                    <div className="card p-4 pt-3">
                        <div className="table-responsive pt-3">
                        <div className="  bg-error mb-0 ms-auto ms-auto">
                            {errInfo}
                           </div> 
                            <table className="text-sm table table-striped mb-0">
                                <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                        <th className="p-2 text-nowrap">學生編號</th>
                                        <th className="p-2 text-nowrap">姓名</th>
                                        <th className="p-2 text-nowrap">年級</th>
                                        <th className="p-2 text-nowrap">密碼</th>
                                        <th className="p-2 text-nowrap">備註</th>
                                        <th className="p-2 text-nowrap" style={{ width: '100px' }}>
                                            功能
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentGroupStudentList.map((student) => (
                                        <tr>
                                            <td>{student.StuNo}</td>
                                            <td>{student.StuName}</td>
                                            <td>{student.StuGrade}</td>
                                            <td>{student.Password}</td>
                                            <td>{student.Remark}</td>
                                            <td className="text-nowrap">
                                                <span className="material-icons text-dark pointer me-3 date-remove" onClick={() => deleteStudent(student)} title="刪除">
                                                    delete_outline
                                                </span>
                                                <span
                                                    onClick={() => openStudentEditModal('編輯', student)}
                                                    className="material-icons text-dark pointer date-remove"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editModal2"
                                                    title="編輯"
                                                >
                                                    edit
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr className="horizontal dark" />

                    <hr className="horizontal dark" />
                    <div className="pt-3 mt-4"></div>
                </div>
            </div>
            <BSModal title={'新增小隊內容'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                {/* {errMsg} */}
            </BSModal>

            {/* 新增小隊內容  modal-start*/}
            <Modal show={isOpenEdit} onHide={() => setIsOpenEditGroup(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="">{mode}小隊</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-auto ">
                    <form role="form text-left">
                        <div className="row mx-auto " id="a-m-g">
                            <div className="col-12">
                                <div>
                                    {formDataGroup.GroupNo ? (
                                        <>
                                            小隊編號：<span>{formDataGroup.GroupNo}</span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="groupName" className="fs-14">
                                    小隊名稱
                                </label>
                                <div className="input-group">
                                    <input
                                        name="小隊名稱"
                                        id="groupName"
                                        data-pattern="^(?!\s*$)[\u4E00-\u9FFFa-zA-Z0-9]{1,15}$"
                                        type="text"
                                        className="form-control fs-16"
                                        placeholder="不可空白，中文8個字內，中英數混用15個字為限"
                                        value={formDataGroup.Groupname}
                                        onChange={(e) => setFormDataOnChange(e, 'Groupname')}
                                    />
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="groupName" className="fs-14">
                                    競賽/學習
                                </label>
                                <div className="input-group">
                                    <select className="form-control fs-16" onChange={(e) => setFormDataOnChange(e, 'CompLearn')}>
                                        <option value="">請選擇</option>
                                        {['參加競賽', '學習,不參加競賽', '取消報名'].map((option, index) => (
                                            <option key={index} value={option} selected={option == formDataGroup?.CompLearn?.trim()}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label className="fs-14">指導老師</label>

                                {applyInfo?.ApplyNo ? (
                                    <DropdownSelect
                                        order={0}
                                        groupNo={formDataGroup?.GroupNo}
                                        applyNo={applyInfo?.ApplyNo}
                                        target={'教師清單'}
                                        selectValue={selectTeacher1}
                                        setSelectTeacher={setSelectTeacher1}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div className="col-12 mt-3">
                                {/* <div className="input-group">
                                    <input type="text" className="form-control fs-16" placeholder="" 
                                    value={formDataGroup.teacher2} 
                                    onChange={(e)=>setFormDataOnChange(e ,'teacher2')}/>
                                </div> */}
                                {applyInfo?.ApplyNo && selectTeacher1 ? (
                                    <DropdownSelect
                                        order={1}
                                        groupNo={formDataGroup?.GroupNo}
                                        applyNo={applyInfo?.ApplyNo}
                                        target={'教師清單'}
                                        selectValue={selectTeacher2}
                                        setSelectTeacher={setSelectTeacher2}
                                        selectTeacher1={selectTeacher1}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div className="col-12 mt-3">
                                <label className="fs-14">備註</label>
                                <div className="input-group">
                                    <textarea
                                        rows={2}
                                        className="form-control fs-16"
                                        placeholder="最多20字"
                                        value={formDataGroup?.Remark}
                                        onChange={(e) => setFormDataOnChange(e, 'Remark')}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsOpenEditGroup(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" disabled={btndisable} onClick={() => insertOrUpdateGroupInfo(mode)}>
                        儲存
                    </button>
                </Modal.Footer>
            </Modal>
            {/*  學生*/}
            <Modal show={isOpenEditStudent} onHide={() => setIsOpenEditStudent(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title">{mode}學生資料</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-auto">
                    <form role="form text-left">
                        <div className="row mx-auto">
                            <div className="col-12">
                                <div>
                                    {formDataStudent.StuNo ? (
                                        <>
                                            學生編號：<span>{formDataStudent.StuNo}</span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="StuName" className="fs-14">
                                    姓名
                                </label>
                                <div className="input-group">
                                    <input
                                        name="姓名"
                                        id="StuName"
                                        data-colname="ss"
                                        data-pattern="^[\u4e00-\u9fa5a-zA-Z]{1,20}$"
                                        type="text"
                                        className="form-control fs-16"
                                        placeholder="不可空白，1-20字"
                                        value={formDataStudent.StuName?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'StuName')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="StuGrade" className="fs-14">
                                    年級 (4-9)
                                </label>
                                <div className="input-group">
                                    <input
                                        name="年級"
                                        id="StuGrade"
                                        type="number"
                                        min={4}
                                        max={9}
                                        className="form-control fs-16 cursor-only"
                                        placeholder=""
                                        style={{
                                            caretColor: 'transparent',
                                            cursor: 'pointer'
                                            // 设置光标颜色
                                        }}
                                        value={formDataStudent.StuGrade?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'StuGrade')}
                                        onKeyDown={(e) => {
                                            // 允许上下箭头和Tab键
                                            if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown' && e.key !== 'Tab') {
                                                e.preventDefault(); // 禁止其他按键输入
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="Password" className="fs-14">
                                    密碼
                                </label>
                                <div className="input-group">
                                    <input
                                        name="密碼"
                                        data-pattern="^[^\s]{6,20}$"
                                        id="Password"
                                        type="text"
                                        className="form-control fs-16"
                                        placeholder="6~20碼"
                                        value={formDataStudent?.Password?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'Password')}
                                    />
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="Remark" className="fs-14">
                                    備註
                                </label>
                                <div className="input-group">
                                    <textarea
                                        data-pattern="^.{0,20}$"
                                        name="備註"
                                        rows={2}
                                        className="form-control fs-16"
                                        placeholder="最多20字"
                                        value={formDataStudent?.Remark?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'Remark')}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsOpenEditStudent(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => insertOrUpdateStudentInfo(mode)}>
                        儲存
                    </button>
                </Modal.Footer>
            </Modal>

            <BSModal title={'欄位輸入錯誤'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                <div dangerouslySetInnerHTML={{ __html: errMsg.replace(/\n/g, '<br>') }} />
            </BSModal>
        </>
    );
};
export default EpApplyGroup;
