import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';

// 定義 Props 類型
interface ContactModalProps {
    show: boolean;
    handleClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>聯絡資訊</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 text-dblue">
            
                <div className="mt-4 mb-2">閱讀基金會：</div>
                <div>(國小A、B組)莊子朋組長，電話：049-2566102分機128</div>
                <div className="my-2">
                (國中C、D組)莊宇揚組長，電話：049-2566102分機110</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    關閉
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ContactModal

// 測試用按鈕，示範如何打開 Modal
// const App: React.FC = () => {
//     const [showModal, setShowModal] = useState<boolean>(false);

//     return (
//         <div>
//             <Button variant="primary" onClick={() => setShowModal(true)}>
//                 開啟聯絡資訊
//             </Button>

//             <ContactModal show={showModal} handleClose={() => setShowModal(false)} />
//         </div>
//     );
// };

// export default App;
