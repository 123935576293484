import React, { useContext, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import { handleLink } from '../../modules/utils';
import ApplyFunctionBar from './component/applyFunctionBar';
import { IApplyFormData, IApplyTopic, IGroupInfo, IGroupStudent, IOpinion, ISearchDataSummary } from '../../interfaces/EpModal';
import { getEPApplyByNo } from '../../services/EP/EpLoginService';
import { getDrGroupPrimeByYrApplyNo, getDrStudentByGroup } from '../../services/EP/EPApplyGroupService';
import { Export2Word, ReacherArPictureName_before, currentClass, downloadImage, formatDate, formatDateTime, getLoginInfo, loadCtrlAndDisable, modeGlobal, replaceNewline } from './tool/helper';
import { getOpinionByGroupNo, getSubSummarySubCollectByGroupNo, getSubTopicByGroupNo } from '../../services/EP/EpApplyExploreService';
import AllOpinion from './component/allOpinion';
import { MyContext } from '../home';
import { useControlCheck } from './HOC/withControlCheck';
import NoEntry from './tool/NoEntry';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const EpApplyExplore: React.FunctionComponent<IPageProps> = (props) => {

    const [opinion, setOpinion] = useState<IOpinion>();
    // const [openEditModal, setOpenEditModal] = useState(false);

    /**  */
    const [subTopicBeforeFeedback, setSubTopicBeforeFeedback] = useState<IApplyTopic[]>();
    const [subTopicAfterFeedback, setSubTopicAfterFeedback] = useState<IApplyTopic[]>();
    const [searchDataList, setSearchDataList] = useState<ISearchDataSummary[]>();
    const [groupDataList, setGroupDataList] = useState<IGroupInfo[]>([]);
    const [applyInfo, setApplyInfo] = useState<IApplyFormData>();
    /** 目前選得隊伍 */
    const [selectedGroup, setSelectedGroup] = useState<IGroupInfo>();
    /** 目前選擇的隊伍明細 */
    const [currentGroupStudentList, setCurrentGroupStudentList] = useState<IGroupStudent[]>([]);


    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();

    const pageName= "" ;
    let context = useContext(MyContext);
    useEffect(() => {
        loadCtrlAndDisable(sessionStorage.getItem('role') || 'guest', '專題探究');
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            let groupList = await fetchDataGroup();
            setSelectedGroup(groupList[0]);
        };
        getEPApplyByLoginID(sessionStorage.getItem('LoginID') + '');
        fetchData();
        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }

    }, []);

    useEffect(() => {
        //取得opinion
        fetchDataOpinion();
        //取得 子題
        fetchDataSubTopic();

        fetchDataSubSummarySubCollectByGroupNo();
    }, [selectedGroup]);


    const fetchDataOpinion = async () => {
        if (selectedGroup) {
            let opinion = (await getOpinionByGroupNo(selectedGroup.GroupNo)) as IOpinion[];
            setOpinion(opinion[0]);
        }
    };
    /** 取得子題資料*/
    const fetchDataSubTopic = async () => {
        if (selectedGroup) {
            //取得回饋前 子題
            let subTopicbefore = (await getSubTopicByGroupNo(0, selectedGroup.GroupNo)) as IApplyTopic[];

            console.log('subTopicbefore ', subTopicbefore);
            setSubTopicBeforeFeedback(subTopicbefore);

            //取得回饋後子題
            let subTopicAfter = (await getSubTopicByGroupNo(1, selectedGroup.GroupNo)) as IApplyTopic[];

            console.log('subTopicAfter ', subTopicAfter);
            setSubTopicAfterFeedback(subTopicAfter);
        }
    };

    const fetchDataSubSummarySubCollectByGroupNo = async () => {
        if(selectedGroup?.GroupNo){
            let rsp = (await getSubSummarySubCollectByGroupNo(1, selectedGroup.GroupNo)) as ISearchDataSummary[];
            setSearchDataList(rsp || {});
        }
 
    };

    const fetchDataGroupStudent = async (groupNo: string) => {
        /**取得學生資料 */
        let groupStudentList = (await getDrStudentByGroup(groupNo)) as IGroupStudent[];
        setCurrentGroupStudentList(groupStudentList);
    };

    /**  取資料 */
    const fetchDataGroup = async (): Promise<IGroupInfo[]> => {
        let currentLoginInfo = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo);
        let GroupDataList = (await getDrGroupPrimeByYrApplyNo(currentClass, currentLoginInfo.LoginID)) as IGroupInfo[];
        setGroupDataList(GroupDataList);
        return GroupDataList;
    };

    /** 取得登入者基本資訊 (申請者)*/
    const getEPApplyByLoginID = async (loginID: string) => {
        let rsp = await getEPApplyByNo(loginID);
        setApplyInfo(rsp[0] as IApplyFormData);
    };




    const homePage ='專題探究'
    const isView = useControlCheck(homePage);
    if (!isView) {
        return <NoEntry />;
    }
   

    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <ApplyFunctionBar crtlInfoList={context?.crtlInfoList} />
                    </div>
                    <div className="text-center h5 mt-4">專題探究</div>
                    <div className="dropdown" style={{ width: 'fit-content' }}>
                        <button
                            className="btn text-second bg-second dropdown-toggle mb-0"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ minWidth: '200px' }}
                        >
                            {selectedGroup?.Groupname}
                        </button>
                        <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                            {groupDataList.map((group) => (
                                <li onClick={() => setSelectedGroup(group)}>
                                    <div className="dropdown-item d-flex">
                                        {group.GroupNo}
                                        {group.Groupname}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div id="title-info" hidden>
                        {selectedGroup?.Groupname}({selectedGroup?.GroupNo}) 
                    </div>
           
                    {/* {modeGlobal=="發布" &&selectedGroup?.GroupNo?(<AllOpinion propClassName={currentClass} propGroupNo= {selectedGroup?.GroupNo}/>):(<>沒有資料</>)} */}
                    {selectedGroup?.GroupNo?(<AllOpinion 
                    propClassName={currentClass} 
                    propGroupNo= {selectedGroup.GroupNo}/>):(<>沒有資料</>)}
                  


                    <div className="pt-3 mt-4"></div>
                </div>
            </div>
            <FooterPage />

            {/* <!-- 階段1 教學 --> */}
            <div className="modal fade" id="teach1" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段1 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/HL4VzukgbKTTV9"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px;max-width: 620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/1202301pptx" title="數位閱讀專題探究競賽 - 怎樣選擇題目" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣選擇題目
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段2 教學 --> */}
            <div className="modal fade" id="teach2" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段2 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/toNtzJl5dVvQVd"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/2202301pptx" title="數位閱讀專題探究競賽 - 怎樣規劃探究架構" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣規劃探究架構
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段3 教學 --> */}
            <div className="modal fade" id="teach3" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段3 教學 - 怎樣選擇題目</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px;', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/g8zXWc7qtfNNjn"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/3202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣選擇題目
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段3.2 教學 --> */}
            <div className="modal fade" id="teach3-2" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段3 教學 - 怎樣判斷資料的可信度</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/r5peeTpp761fAh"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/4202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣判斷資料的可信度
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段4 教學 --> */}
            <div className="modal fade" id="teach4" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段4 教學 - 怎樣歸納統整資料</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/sF3WTJjXayexwq"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/5202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣歸納統整資料
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段4.2 教學 --> */}
            <div className="modal fade" id="teach4-2" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段4 教學 - 怎樣提出結論或發現</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/8DYUbEJVfM11n2"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/6202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣提出結論或發現
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default EpApplyExplore;
