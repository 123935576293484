

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo, getOpinionByStuNo } from '../../services/EP/EpApplyStudentService';
import { alertEx, currentClass, fetchDataSubTopic, getLoginInfo, loadCtrlAndDisable, setSessionStudentDisplayInfo } from './tool/helper';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';

import { IOpinion, IsubTopic, TeacherGroupInfo, TeacherlInfo } from '../../interfaces/EpModal';
import { getSubTopicByGroupNo } from '../../services/EP/EpApplyExploreService';
import TeacherFunctionBar from './component/teacherFunctionBar';
import Level34 from './component/level34';
import { getDrGroupByTeaNo, getDrTeacherByNo } from '../../services/EP/EpApplyTeacherService';
import GroupSelect from './component/groupSelect';
import { useControlCheck } from './HOC/withControlCheck';
import NoEntry from './tool/NoEntry';


export interface StudentDisplay {

  ApplyName: string
  ApplyNo: string
  ApplyTitle: string
  GroupName: string
  GroupNo: string
  SchoolName: string 
  StuName: string 
  StuNo: string 
  TeaName: string 
  TeaNo: string 
  TeaTitle: string 
  Team: {
    TStuName: string,
    TStuNo: string
    checked: boolean
  }[]
  Teachers: {
    TeaNo: string, TeaName: string, TeaTitle: string

  }[]
}

// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpTeachLevel3: React.FunctionComponent<IPageProps> = () => {

  const [currentLoginInfo, setCurrentLoginInfo] = useState<any>()
  const [studentInfo, setStudentInfo] = useState<StudentDisplay>()
  const [isReload, setIsReload] = useState<boolean>(true);
  const [opinion, setOpinion] = useState<IOpinion>();
  const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>();


 
  const [teaccherGroupInfoList, setTeacherGroupInfoList] = useState<TeacherGroupInfo[]>([]);
  const [selectedGroup, setSelectGroupInfo] = useState<TeacherGroupInfo>();
  const [role, setRole] = useState('')
  const [teacherInfo, setTeacherInfo] = useState<TeacherlInfo>()



  /**
 * 從伺服器獲取教師相關數據
 *
 * @async
 * @function fetchDataTeacher
 * @description
 * 1. 從本地端獲取當前登入資訊。
 * 2. 調用 `getDrTeacherByNo` API，以登入 ID 獲取教師資料。
 * 3. 將獲取的教師資料設置為狀態並更新當前登入資訊。
 * 
 * @throws 對於任何例外情況，會觸發錯誤訊息提示並輸出錯誤到主控台。
 *
 * @example
 * fetchDataTeacher();
 */
  const fetchDataTeacher = async () => {
    let currentLoginInfo = getLoginInfo();
    try {
      let teacherRsp = await getDrTeacherByNo(currentLoginInfo.LoginID) as any[]
      setTeacherInfo(teacherRsp[0] as TeacherlInfo)
      setCurrentLoginInfo(currentLoginInfo)
    } catch (ex) {
      alert("取得資料發生錯誤:101 ")
      console.log("ex 101", ex)
    }


  }
  useEffect(() => {
    fetchTeacherGroups();
  }, []);
  const fetchTeacherGroups = async () => {
    let currentLoginInfo = getLoginInfo();
    setRole(currentLoginInfo.LoginStatus)
    console.log('fetchData...', currentLoginInfo.LoginID.trim());
    const rsp = (await getDrGroupByTeaNo(currentClass, currentLoginInfo.LoginID.trim())) as any;
    if (rsp) {
      console.log('rsp---', rsp?.Groups);
      setTeacherGroupInfoList(rsp?.Groups);
      setSelectGroupInfo(rsp?.Groups[0]);
    }
  };


  const pageName ='階段3和4'
    useEffect(() => {
        loadCtrlAndDisable(sessionStorage.getItem('role') || 'guest', pageName);
    }, []);
  useEffect(() => {

    FetchDataOpinion();

  }, [isReload]);

  useEffect(() => {
    fetchDataSubTopic();
  }, [studentInfo, isReload]);

  const reload = async () => {
    await FetchDataOpinion();
  };


  /** */
  const FetchDataOpinion = async () => {
    let currentLoginInfo = await getLoginInfo();
    setCurrentLoginInfo(currentLoginInfo);
    const rsp = (await getOpinionByStuNo(currentLoginInfo.LoginID)) as IOpinion[];

    const opinion = rsp[0];
    if (opinion) {
      setOpinion(opinion);
    }
  };


  /** 取得子題資料*/
  const fetchDataSubTopic = async () => {
    if (studentInfo?.GroupNo) {
      let subTopicbefore = (await getSubTopicByGroupNo(0, studentInfo?.GroupNo)) as IsubTopic[];
      setSubTopicBeforeFeedbackList(subTopicbefore);
    }
  };

  const isView = useControlCheck(pageName);
    if (!isView) {
        return <NoEntry />;
    }
   
  return (
    <>
      <TopNavPage1 />
      <div className="section pt-3">
        <div className="container px-3 px-md-4">
          <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <h5 className="title mb-3 me-3">專題探究競賽</h5>
            <TeacherFunctionBar />
          </div>

          <GroupSelect selectGroupInfo={selectedGroup} teaccherGroupInfoList={teaccherGroupInfoList} setSelectGroupInfo={setSelectGroupInfo} />


          <div id="title-info" style={{ display: "none" }} >
            {selectedGroup?.SchoolName.trim()}-{selectedGroup?.GroupNo.trim()}-{selectedGroup?.GroupName.trim()}
          </div>
          <Level34
            selectGroupInfo={selectedGroup}
            PageName='階段3和4'
            groupNo={selectedGroup?.GroupNo}
            role={role}
            opinion={opinion}
            studentInfo={studentInfo}
            teacherInfo={teacherInfo}
            setStudentInfo={setStudentInfo}
            isFeedback={1} />
        </div>
      </div>
      <FooterPage />
    </>

  )


}

export default EpTeachLevel3;

