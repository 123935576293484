import React, { useContext, useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { crtlInfo } from '../../../interfaces/EpModal';
import { currentClassForBtn, getAuthData, getcontrol, logOut } from '../tool/helper';
import { getDrControlByClassNow } from '../../../services/EP/EPbtnEableService';
import { MyContext } from '../../home';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const StudentFunctionBar: React.FunctionComponent<IPageProps> = (props) => {

    interface IProp {
        currentPage?: string;
    }
    useEffect(() => {
        getCtrls(sessionStorage.getItem('role') || '');
    }, []);

    const context = useContext(MyContext);
    const getCtrls = async (control: string) => {
        let ctrlInfo = await getAuthData(control);
        context?.setCtrlInfoList(ctrlInfo);
    };

    const getCtrl = (CtrlName: string) => {
        let result;
        if (context?.crtlInfoList?.length) {
            result = context?.crtlInfoList?.find((x) => {
                return x.Control.trim() === CtrlName.trim();
            });
        }
        context?.setCurrentRole(result);
      
        return result;
    };
    const link = (ctrl: string, route: string) => {
        if (getCtrl(ctrl)) {
            handleLink(route);
        } else {
        }
    };

    const disableStyle = {
        color: 'gray',
        background: '#dddddd'
    };

    return (
        <>

            <div className="text-center text-lg-end exclude">
                <a
                    href={getCtrl('主頁')  ? '/epStudentHome' : undefined}
                    onClick={() => {
                        link('主頁', '/epStudentHome');
                    }}
                    style={getCtrl('主頁') ?{}:disableStyle }
                    
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    主頁
                </a>

                <a
                    href={getCtrl('階段1和2') ? '/epStudLevel1' : ''}
                    onClick={() => {
                        link('階段1和2', '/epStudLevel1');
                    }}
                    style={getCtrl('階段1和2') ?{}:disableStyle }

                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    階段1和2
                </a>

                <a
                    href={getCtrl('專家回饋') ? '/epStudExpert' : ''}
                    style={getCtrl('專家回饋') ? {} : disableStyle}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    專家回饋
                </a>
                <a
                    href={getCtrl('階段1和2(回饋後)') ? '/epStudfeedback' : ''}
                    style={getCtrl('階段1和2(回饋後)') ? {} : disableStyle}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    階段1和2(回饋後)
                </a>
                <a
                    href={getCtrl('階段3和4') ? '/epStudLevel3' : ''}
                    style={getCtrl('階段3和4') ? {} : disableStyle}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    階段3和4
                </a>
                <a
                    href={getCtrl('成果報告') ? '/epStudResult' : ''}
                    style={getCtrl('成果報告') ? {} : disableStyle}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    成果報告
                </a>
                <a
                    onClick={() => {
                        logOut();
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
            </div>
        </>
    );
};
export default StudentFunctionBar;
