import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { IInsertTeacher, ITearcherInfo, IUpdateTeacher } from '../../../interfaces/EpModal';
import { IExTeacher } from '../../../interfaces/ExTeacher';
import { insertDrTeacherByApplyNo, updateDrTeacherByNo } from '../../../services/EP/EpApplyTeacherService';
import { Modal } from 'react-bootstrap';

import { BSModal } from '../../../components/modal/bsmodal';
import { debug } from 'console';

interface IModalComponentProps {
    mode: '新增' | '編輯' | '';
    teacherInfo: ITearcherInfo;
    applyNo: string|undefined;
    onClose: () => void;
    onDataChange: (newData: ITearcherInfo) => void;
    isOpen: boolean;
    setIsModalOpen: (val: boolean) => void;
}

const ApplyNewTeacherModal: React.FunctionComponent<IModalComponentProps> = ({ applyNo, setIsModalOpen, isOpen, teacherInfo, onClose, onDataChange, mode }) => {
    const [inputData, setInputData] = useState(teacherInfo);
    const [formData, setFormData] = useState<ITearcherInfo>({} as ITearcherInfo);
    const [title, setTitle] = useState('');
    const [btndisable,setBtndisable] =useState(false)
    const [openErrModal ,setOpenErrModal] =useState(false)
    const [errorMsg,setErrorMsg] =useState("")
    useEffect(() => {
        setTitle(teacherInfo.TeaTitle);
        console.log('teacherInfo', teacherInfo);
    }, [teacherInfo]); // 將 teacherInfo 加入相依性陣列

    useEffect(() => {
        setFormData({ ...teacherInfo });
    }, [teacherInfo]); // 將 teacherInfo 加入相依性陣列

    /** 當設定畫面有改變時*/
    const setFormDataOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr: string) => {
        setFormData({ ...formData, [attr]: e.target.value } as ITearcherInfo);
    };
    const handleTitleChange = (e: React.ChangeEvent<HTMLSelectElement>, attr: string) => {
        setFormData({ ...formData, [attr]: e.currentTarget.value } as ITearcherInfo);
    };

    const handleInputChange = (e: any) => {
        setInputData(e.target.value);
    };

    const handleSave = () => {
        onDataChange(inputData);
    };

    // return (
    //   <div className="modal">
    //     <h3>Modal Content</h3>
    //     <input type="text" value={inputData} onChange={handleInputChange} />
    //     <button onClick={handleSave}>Save</button>
    //     <button onClick={onClose}>Cancel</button>
    //   </div>
    // );
    /** 驗證 */
    // validate-ref
    const validate = () => {
        // alert('驗證')
        let noError = true;
        let errMsg = '';

        if (formData?.TeaName.trim() === '') {
            noError = false;
            errMsg = errMsg + '教師姓名不可空白,';
        }
        if (formData?.TeaName.trim().length > 10) {
            noError = false;
            errMsg = errMsg + '教師姓名不可超過10碼,';
        }
        debugger
        if (!formData?.TeaTitle|| formData?.TeaTitle?.trim() === '請選擇') {
            noError = false;
            errMsg = errMsg + '請選擇職稱,';
        }

        // 檢查手機
        if (formData?.TeaCell.trim() === '') {
            noError = false;
            errMsg = errMsg + '手機不可空白,';
        }

        //檢查密碼
        if (!formData?.Password || formData?.Password?.trim() === '') {
            noError = false;
            errMsg = errMsg + '密碼不可空白,';
        } else {
            // alert(formData?.Password)
            const regChk: RegExp = /^([a-zA-Z0-9]){6,}/;
            if (!regChk.test(formData.Password.trim())) {
                noError = false;
                errMsg = errMsg + '密碼設定錯誤(至少6碼並為英數字),';
            }
        }

        //檢查email
        if (formData.TeaEmail.trim() === '') {
            noError = false;
            errMsg = errMsg + 'Email不可空白,';
        }
        if (formData.TeaEmail.trim() !== '') {
            const emailChk: RegExp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
            if (!emailChk.test(formData.TeaEmail.trim())) {
                noError = false;
                errMsg = errMsg + 'Email格式錯誤,';
            }
        }

        if (formData.Remark.trim() !== '') {
            // 判斷 Remark 是否超過 10 個字
            if (formData.Remark.trim().length >20) {
                noError = false;
                errMsg = errMsg + '備註不可超過 20 個字,';
            }}

        debugger
        if (noError) {
            errMsg =''
        } else {
            errMsg = errMsg.substring(0, errMsg.length - 1) + '。';
        }
        return errMsg;
    };

    const updateDate = async (action: string) => {
        try {
            let errorMsg = validate();
            if (!errorMsg) {
                let params: IUpdateTeacher | IInsertTeacher = {
                    groupno: formData.GroupNo?.trim() || '',
                    teaname: formData.TeaName?.trim() || '',
                    teatitle: formData.TeaTitle?.trim() || '',
                    teacell: formData.TeaCell?.trim() || '',
                    teaemail: formData.TeaEmail?.trim() || '',
                    password: formData.Password?.trim() || '',
                    remark: formData.Remark?.trim() || ''
                } as IUpdateTeacher;

                if (mode === '編輯') {
                    setBtndisable(true)
                    await updateDrTeacherByNo(params, formData.TeaNo);
                    setBtndisable(false)
                    alert('更新教師完成!');
                } else if (mode === '新增') {
                    params = {
                        TeaName: formData.TeaName?.trim(),
                        TeaTitle: formData.TeaTitle?.trim(),
                        TeaCell: formData.TeaCell?.trim(),
                        TeaEmail: formData.TeaEmail?.trim(),
                        Password: formData.Password?.trim(),
                        Remark: formData.Remark.trim(),
                        applyno: applyNo
                    } as IInsertTeacher;
                    setBtndisable(true)
                    if(applyNo){
                        await insertDrTeacherByApplyNo(params, applyNo);
                    }
                    setBtndisable(false)
                    alert('新增教師完成!');
                }
                console.log('更新老師時檢查' + errorMsg);
                onClose(); //關閉視窗
            }else{

             setErrorMsg(errorMsg)
             setOpenErrModal(true)

            }
        } catch (ex) {
            alert('發生錯誤: \n' + JSON.stringify(ex));
        }

        // 檢查
    };

    return (
        <>
            <Modal show={isOpen} onHide={() => setIsModalOpen(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title">{mode}指導老師</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <form role="form text-left">
                        <div className="row mx-auto">
                            <div className="col-12">
                                {mode == '新增' ? (
                                    <></>
                                ) : (
                                    <div>
                                        老師編號：<span>{formData.TeaNo}</span>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 mt-3">
                                <label className="fs-14">姓名</label>
                                <div className="input-group">
                                    <input type="text" className="form-control fs-16" pattern="^(?=.*\S).{1,10}$" onChange={(e) => setFormDataOnChange(e, 'TeaName')} value={formData.TeaName} />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-3">
                                <label className="fs-14">職稱</label>
                                {/* <select className="form-control fs-16" value ={title} onChange={(e)=>setTitle(e.currentTarget.value)}> */}
                                <select className="form-control fs-16" value={formData.TeaTitle?.trim()} onChange={(e) => setFormData({ ...formData, TeaTitle: e.currentTarget.value } as ITearcherInfo)}>
                                    {/* <select className="form-control fs-16" defaultValue ={}> */}
                                    <option value="">請選擇</option>
                                    <option value="導師">導師</option>
                                    <option value="主任">主任</option>
                                    <option value="組長">組長</option>
                                    <option value="圖推老師">圖推老師</option>
                                    <option value="科任老師">科任老師</option>
                                </select>
                            </div>

                            <div className="col-12 col-md-6 mt-3">
                                <label className="fs-14">密碼</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control fs-16"
                                        placeholder="6~20碼"
                                        pattern="^(?=.*\S).{1,10}$"
                                        value={formData.Password}
                                        onChange={(e) => setFormDataOnChange(e, 'Password')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-3">
                                <label className="fs-14">連絡電話(手機或學校電話)</label>
                                <div className="input-group">
                                    <input type="text" className="form-control fs-16" value={formData.TeaCell} onChange={(e) => setFormDataOnChange(e, 'TeaCell')} />
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label className="fs-14">Email</label>
                                <div className="input-group">
                                    <input type="text" className="form-control fs-16" placeholder="輸入Email" value={formData.TeaEmail?.trim()} onChange={(e) => setFormDataOnChange(e, 'TeaEmail')} />
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label className="fs-14">備註</label>
                                <div className="input-group">
                                    <textarea rows={2} className="form-control fs-16" placeholder="最多20字" value={formData.Remark} onChange={(e) => setFormDataOnChange(e, 'Remark')}></textarea>
                                </div>
                            </div>
                        </div>
             
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsModalOpen(false)}>
                        取消
                    </button>
                    <button type="submit" className="btn btn-primary" onClick={() => updateDate(mode)}>
                        儲存
                    </button>
                </Modal.Footer>
            </Modal>
            <BSModal title={'欄位輸入錯誤'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                {errorMsg}
            </BSModal>
            {/* </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" className="btn btn-primary" onClick={()=>updateTeacherInfo()}>儲存</button>
        </div>
      </div> */}
        </>
    );
};

export default ApplyNewTeacherModal;
