

import React, {  useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import TeacherFunctionBar from './component/teacherFunctionBar';
import FooterPage from '../footer';
import TopNavPage1 from '../topNav1';
import { getDrGroupByTeaNo, getDrTeacherByNo, getOpinionByGroupOpIdx } from '../../services/EP/EpApplyTeacherService';
import { currentClass, formatDate, getLoginInfo, loadCtrlAndDisable } from './tool/helper';
import Schedule from './component/schedule';
import { TeacherlInfo } from '../../interfaces/EpModal';
import { useControlCheck } from './HOC/withControlCheck';
import NoEntry from './tool/NoEntry';
import ScheduleDB from './component/scheduleDB';



interface IGroupInfo {
  FlagOK: string 
  GroupName: string 
  GroupNo : string
  IsEnterFinal: string
  PptDate:string
  Team :string 
  Topic: string
  UploadDate: string
}
  



 interface ITeacherInfoDisplay { 
  FlagOK :string 
  GroupName:string 
  GroupNo:string 
  IsEnterFinal:string 
  PptDate:string 
  Team: {StuNo: string
     , StuName: string } []
     TeamString?:string 
  /** 探究題目 */   
  data12:string 
  UploadDate :string 
  Remark :string 
  Topic :string 
  }



const EpTeachrHome: React.FunctionComponent<IPageProps> =() => {


  const [ currentLoginInfo , setCurrentLoginInfo] = useState<any> ()
  const [ teacherInfo , setTeacherInfo ] = useState<TeacherlInfo>()
  const [ teacherGroupInfoList , setTeacherGroupInfoList] = useState<ITeacherInfoDisplay[]> ()
  
  const pageName ='指導老師主頁'
  useEffect(() => {
    loadCtrlAndDisable(sessionStorage.getItem('role') || 'guest', pageName);
}, []);


 useEffect(()=>{
  fetchDataTeacher();
 },[]) 
 


 /** */
 const fetchDataTeacher = async ()=> {
  let currentLoginInfo = getLoginInfo();
  try{
    let teacherRsp =  await getDrTeacherByNo(currentLoginInfo.LoginID) as any[]
    setTeacherInfo(teacherRsp[0] as TeacherlInfo)
    setCurrentLoginInfo(currentLoginInfo)
    const rsp  =  await getDrGroupByTeaNo(currentClass,currentLoginInfo.LoginID.trim()) as any ;
    if (rsp && rsp) {
      
      let groupList : ITeacherInfoDisplay[] = rsp.Groups ;
      setTeacherGroupInfoList(rsp.Groups)
      
      await addData11(groupList);
    }
    

    }catch(ex){
        alert("取得資料發生錯誤:"+ JSON.stringify(ex))
        console.log("ex 101",ex)
    }        

  
 }


 const addData11 = async (groupList: ITeacherInfoDisplay[]) => {
  for (const item of groupList) {
    let data = await fetchDataindex11(1, '12', item.GroupNo) as any;
    item.data12 = data;
    console.log('item', item);
  }

 }

 /** 取得1-1探究題目 */
 const  fetchDataindex11 = async (isfeedback :0|1 , index:string   , groupNo:string )=>{
  try{
    let  data  = await  getOpinionByGroupOpIdx(isfeedback, index,groupNo) as any
    return data[0]
  }catch(ex){
    alert("發生錯誤 :" +JSON.stringify(ex))
  }
 }

 /** */
const arrayToString = (arr :any[] ,attrube :string )=>{
  let  itemArr :string[] =[]
  arr.forEach(item=>{

    itemArr.push(item[attrube])
  })
  return itemArr.join("/")
}




const isView = useControlCheck(pageName);


    if (!isView) {
        return <NoEntry />;
    }
   

    
return (
    <>
    {/* {JSON.stringify(teacherGroupInfoList)} */}
       <TopNavPage1 />
       <div className="section pt-3">
        <div className="container px-3 px-md-4">
      <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
        <h5 className="title mb-3 me-3">專題探究競賽</h5>


        <TeacherFunctionBar/>

      </div>
      <div className="text-center h5 mt-4">參加 {currentClass}柯華葳線上數位閱讀專題探究競賽</div>
      <div className="flex-parent mt-5">
      
      {/* <Schedule/> */}
      <ScheduleDB/>
        <div className="pt-3 mt-4"></div>
      </div>


      <div className="text-center fw-bolder text-muted fs-18">歡迎{teacherInfo?.schoolName} {teacherInfo?.TeaName} 指導老師</div>
      <div className="text-center mb-4">您的老師編號資料由 {teacherInfo?.ApplyName} 管理</div>
      <div className="card p-3">

        <div className="accordion" id="accordionRental">



          <div className="row font-weight-bolder text-second text-nowrap m-0" style={{background: "#d9dce6"}}>
            <div className="col p-2">小隊編號</div>
            <div className="col p-2">小隊名稱</div>
            <div className="col p-2">報名通過</div>
            <div className="col p-2">進入決賽</div>
       
            <div className="col p-2">成果報告</div>
            <div className="col p-2">成果簡報</div>
            <div className="col p-2">備註</div>
          </div>

          { teacherGroupInfoList?.map((item,index)=>(

            <div className="accordion-item accbg">
            <div className="accordion-header" id="heading1">
              <button className="row fs-14 pointer m-0 accordion-button p-0" data-bs-toggle="collapse" data-bs-target={"#collapse"+index} aria-expanded="false" aria-controls="collapse1">
                <div className="col p-2">{item.GroupNo}</div>
                <div className="col p-2">{item.GroupName}</div>
                <div className="col p-2">{item.FlagOK}</div>
                <div className="col p-2" >{item.IsEnterFinal?"v":"-"}</div>
                <div className="col p-2">{formatDate(item.PptDate)}</div>
                <div className="col p-2">{formatDate(item.UploadDate)}</div> 
                <div className="col p-2 d-flex">{item.Remark}
                  <i className="collapse-close fas fa-angle-down fs-18 ms-auto" aria-hidden="true"></i>
                  <i className="collapse-open fas fa-angle-up fs-18 ms-auto" aria-hidden="true"></i>
                </div>
              </button>
            </div>
            <div id={"collapse"+index} className="accordion-collapse bg-white border-bottom collapse" aria-labelledby="heading1" data-bs-parent="#accordionRental">
              <div className="accordion-body m-0">
                <div className="mb-2">學生：<span>
                  
                  {arrayToString(item.Team,"StuName")}
                  {/* 趙小一 /趙小二 /趙小三 /趙小四 */}
                  </span></div>
                <div>探究題目：<span>{item.Topic ||'(尚未決定)'}</span></div>
              </div>
            </div>
            </div>

))}

       
          {/* <div className="accordion-item accbg">
            <div className="accordion-header" id="heading2">
              <button className="row fs-14 pointer m-0 accordion-button p-0" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                <div className="col p-2">FA001-02</div>
                <div className="col p-2">還不錯隊</div>
                <div className="col p-2"></div>
                <div className="col p-2"></div>
                <div className="col p-2">2023/02/13</div>
                <div className="col p-2"></div>
                <div className="col p-2"></div>
                <div className="col p-2 d-flex">
                  <i className="collapse-close fas fa-angle-down fs-18 ms-auto" aria-hidden="true"></i>
                  <i className="collapse-open fas fa-angle-up fs-18 ms-auto" aria-hidden="true"></i>
                </div>
              </button>
            </div>
            <div id="collapse2" className="accordion-collapse bg-white border-bottom collapse" aria-labelledby="heading2" data-bs-parent="#accordionRental">
              <div className="accordion-body m-0">
                <div className="mb-2">學生：<span>趙小一 /趙小二 /趙小三 /趙小四</span></div>
                <div>探究題目：<span>我們決定探究的題目</span></div>
              </div>
            </div>
          </div> */}

        </div>

      </div>




      <div className="pt-3 mt-4"></div>
    </div>
    </div>
    <FooterPage />
    </>
   
    )


    } 

export default EpTeachrHome;