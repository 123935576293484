import React from 'react';
import TopNavPage1 from '../../topNav1';
import FooterPage from '../../footer';

const NoEntry: React.FC = () => {
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
        backgroundColor: '#fcf8f2' // 米黃色背景
    };

    const messageStyle: React.CSSProperties = {
        fontSize: '15px',
        fontWeight: 'bold',
        color: 'gray', // 柔和灰色文字
        padding: '15px',
        border: 'none', // 浅灰色邊框
        borderRadius: '8px',
        // backgroundColor: '#ffffff' // 白色區塊背景
        backgroundColor: '#ffffff' // 白色區塊背景
    };


    return (
        <div>
            <TopNavPage1 />

            <div style={containerStyle}>
                <div>
                    <div style={messageStyle}>本頁尚未開放</div>
                </div>
            </div>
            <FooterPage />
        </div>
    );
};

export default NoEntry;
