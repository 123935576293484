import { group } from 'console';
// import { ISearchDataSummary } from './Ep';

import { sourceType } from "../pages/ep/tool/helper";

/** 登入時的使用者資訊 from by acc & pwd  */
export interface ILoginUserInfo{
    LoginID:string // FA001
    LoginName  :string //劉測試 
    LoginStatus : 'DrApply'| 'DrTeacher' |'DrStudent' |'Expert'
    role?:string 
}

export interface TeacherGroupInfo {
  FlagOK: boolean;
  GroupName: string;
  GroupNo: string;
  IsEnterFinal: boolean;
  PptDate: string;
  SchoolName:string;
  
  Team: { StuNo: string; StuName: string }[];
}

/** 申請人修改資料表單處  ApplyHome*/
export interface IApplyFormData {
    uid: number ;
    ApplyNo:string
    ApplyName: string
    classnum: number 
    Title: string 
    Tel: string 
    Mobile: string
    Email: string
    Remark: string,
    SchoolNo: string
    SchoolName: string
    SchoolFullName: string
    Type:string
    PubPri: string
    City: string
    Area: string
    Address: string
    Password :string
    SchoolName2 :string

}


export interface IupdateApply{
    applyName:string
    title:string
    classnum:number
    tel:string
    mobile:string
    email:string
    password:string
    type:string
    remark:string
    city:string
    area:string
    schoolNo:string
    schoolName:string
    schoolFullName:string
    pubPri:string
    address:string

}

/** 承辦人目前有新增之老師*/
export interface ITearcherInfo {
    GroupNo:string 
    Password:string
    Remark: string 
    TeaCell :string
    TeaEmail : string 
    TeaName :string  
    TeaNo :string 
    TeaTitle :string 
    uid : string 
}

/** 老師資訊  controller*/
export interface IUpdateTeacher {
    groupno:string
    teaname:string
    teatitle:string
    teacell:string
    teaemail:string
    password:string
    remark:string
}

/**  */
export interface IInsertTeacher{
    TeaName:string
    TeaTitle:string
    TeaCell:string
    TeaEmail:string
    Password:string
    Remark:string   
  }


  /** 新增申請人 newApply*/
  export interface IInsertApply{
    applyName:string,
    mobile:string,
    email:string,
    password:string;
    type:string ;
    /** 班級數 */
    classnum: number |undefined ;
    /** 公私立 */
    pubPr :'公立'|'私立'|'' ;
    /** 國中 高中 */ 
    schoolType :'國中'|'國小'|'' ;
    /** */
    passwordChecked :string ;
    schoolName:string  ;
    city?:string ;
    schoolNo?: string ; 
    area? :string ;
    address?:string ;
    schoolFull?:string ;
 
  } 

/** Apply get Group 資料 */
  export interface  IGroupInfo{
    ApplyNo: string ;
    Class :string ;
    CompLearn :'參加競賽'|'學習,不參加競賽'|'取消報名'|''|null;
    FlagOk: string  
    FlagRevOK :string  ;
    GroupNo :string 
    Groupname :string; 
    PptDate: string ; 
    Remark : string ;
    SchoolFull:string ;
    Schoolname :string ;
    Type :string ;
    UploadDate :string  ;
    uid : string ;
    teacher1:string ;
    teacher2 :string ;
    StuNames? : string ;
    TeaNames?: string ;
    StudentCount ?:number ;
    TeacherCount ?:number ;
  }

  /**  */
  export interface  IGroupStudent{
    Compare: string ;
    GroupNo: string ;
    Password:string ;
    Remark:string ;
    StuGrade: string ;
    StuName: string ;
    StuNo:string ;
    uid: string 
    mode :'編輯'|'新增'|'';
  }



  /** insert 用 */
 export interface IInsertApplyGroup{
    groupName:string,
    schoolName:string,
    schoolFull:string,
    type:string,
    remark:string, 
    compLearn :string
  }

  export interface IInsertApplyStudent {
    StuName:string,
    StuGrade:string,
    Password:string,
    Compare:string,
    Remark:string,     
  }



  export interface IUpdateApplyGroup{
    groupName:string,
    schoolName:string,
    schoolFull:string,
    type:string,
    flagOK:number, //報名審核 傳入1或0
    flagRevOK:number, //五階段完成 傳入1或0
    uploadDate:string, //上傳成果報告日期
    pptDate:string, //上傳PPT日期
    compLearn:string, //參加競賽，學習不參加競賽，取消報名
    remark:string, 
  }



  /**   */
  export interface IUpdateApplyStudent {
    groupno:string,
    stuname:string,
    stugrade:string,
    password:string, 
    compare:string,
    remark:string,
  }


  /** */
  export interface IOpinion {  
     
        GroupID :string  // "1",
        GroupNo :string  // "\"1\".",
        GroupName :string  // "小隊一",
        Pre11 : Param11OrPost11[]|[] ;
        //  [
        //    {
        //     OpinionID:number|undefined
        //     StuID :string  // "1",
        //     StuNo :string  // "2",
        //     StuName :string  // "小明",
        //     DataA :string  // "探究題目",
        //     DataB:  string  // "探究題目理由",
        //     UpdateDate :string  // "2021-01-01 12:00:00"
        //    }
        //  ],
        Post11 :Param11OrPost11 []|[] ,
        /**前-決定探究題目 */
        Pre12_DataA :string  // "前-決定探究題目",
        /** 前-決定探究題目原因 */
        Pre12_DataB :string  // "前-決定探究題目原因",
        /** */
        Pre12_UpdateDate :string  // "2021-01-01 12:00:00",
        /**前-探究題目預測結果原因 */
        Pre13_DataB :string  // "前-探究題目預測結果原因",
        /** */
        Pre13_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 前-遭遇困難 */
        Pre14_DataA :string  // "前-遭遇困難",
        /** 前-解決方式 */
        Pre14_DataB :string  // "前-解決方式",
        /** 前-學到的新思維 */
        Pre14_DataC :string  // "前-學到的新思維",
        /** */
        Pre14_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 前-指導老師留言*/
        Pre19_DataA :string  // "前-指導老師留言",
       /** */ 
        Pre19_UpdateDate :string  // "2021-01-01 12:00:00",
        /**前-遭遇困難 */
        Pre24_DataA :string  // "前-遭遇困難",
        /**前-解決方式*/
        Pre24_DataB :string  // "前-解決方式",
        /** 前-學到的新思維*/
        Pre24_DataC :string  // "前-學到的新思維",
        /** 2021-01-01 12:00:00*/
        Pre24_UpdateDate :string  // "2021-01-01 12:00:00",
        /**前-指導老師留言 */
        Pre29_DataA :string  // "前-指導老師留言",
        /** */
        Pre29_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 專家回饋 */
        ExpoOpinion :string  // "專家回饋",
        /** 後-決定探究題目 */
        Post12_DataA :string  // "後-決定探究題目",
        /** 後-決定探究題目原因*/
        Post12_DataB :string  // "後-決定探究題目原因",
        /** */
        Post12_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-探究題目預測結果原因 */
        Post13_DataB :string  // "後-探究題目預測結果原因",
        /** */
        Post13_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-遭遇困難*/
        Post14_DataA :string  // "後-遭遇困難",
        /** 後-解決方式*/
        Post14_DataB :string  // "後-解決方式",
        /** 後-學到的新思維*/
        Post14_DataC :string  // "後-學到的新思維",
        /** */
        Post14_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-指導老師留言*/
        Post19_DataA :string  // "後-指導老師留言",
        /** */
        Post19_UpdateDate :string  // "2021-01-01 12:00:00",
        /**後-遭遇困難 */
        Post24_DataA :string  // "後-遭遇困難",
        /**後-解決方式 */
        Post24_DataB :string  // "後-解決方式",
        /** 後-學到的新思維 */
        Post24_DataC :string  // "後-學到的新思維",
       /** 24 日期 */ 
        Post24_UpdateDate :string  // "2021-01-01 12:00:00",
        /**後-指導老師留言 */
        Post29_DataA :string  // "後-指導老師留言",
        /** 29日期 */
        Post29_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-回饋意見後修改 */
        Post30_DataA :string  // " 後-回饋意見後修改",
        /**  時間 */
        Post30_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-遭遇困難 */
        Post33_DataA :string  // "後-遭遇困難",
        /** 後-解決方式 */
        Post33_DataB :string  // "後-解決方式",
        /** 後-學到的新思維 */
        Post33_DataC :string  // "後-學到的新思維",
        /** 2021-01-01 12:00:00 */
        Post33_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-指導老師留言 */
        Post39_DataA :string  // "後-指導老師留言",
        /** */
        Post39_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-提出結論與發現 */
        Post42_DataA :string  // "後-提出結論與發現",
        /** */
        Post42_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-比較探究結論 */
        Post43_DataA :string  // "後-比較探究結論", 
        /** */
        Post43_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-遭遇困難 */
        Post44_DataA :string  // "後-遭遇困難",
        /** 後-解決方式 */
        Post44_DataB :string  // "後-解決方式",
        /** 後-學到的新思維 */
        Post44_DataC :string  // "後-學到的新思維",
        /** */
        Post44_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-指導老師留言 */
        Post49_DataA :string  // "後-指導老師留言",
        /** */
        Post49_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-更改前探究題目 */
        Post51_DataA :string  // "後-更改前探究題目",
        /** */
        Post51_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-參加本活動的省思*/
        Post54_DataA :string  // "後-參加本活動的省思",
        
        Post54_UpdateDate :string  // "2021-01-01 12:00:00",
        /** 後-指導老師心得 */
        Post55_DataA :string  // "後-指導老師心得",

        Post55_UpdateDate :string  // "2021-01-01 12:00:00"
        [key: string]: any;
       }



export interface IApplyTopic {
      DeleteDate: string 
      Description: string 
      GroupNo: string 
      Keyword: string 
      SubName: string 
      SubNo : number  
      uid : number
      UpdateDate? :string  
}


/**service-rsp-item 承辦人 */
// >3-1 運用關鍵字詞蒐尋
export interface ISearchDataSummary
{
  SubNo: string  ; 
  SubName: string  ;
  Summaries: Summary[] }

  
/**學生資訊 */
export interface IStudentInfo{
Compare :string 
GroupNo :string   //"FA001-01"
Password : string //"12345"
Remark : string //  "1345" 
StuGrade: string //"5"
StuName: string // "趙小一  "
StuNo: string // "FA001-01-1"
uid:string // 9367
}


export interface Param11OrPost11{
  Type?:"Post"|"Pre"
  OpinionID: number,
  GroupNo: string ,
  StuID: number,
  StuNo: string,
  StuName: string,
  DataA: string,
  DataB: string,
  UpdateDate: string
}


/*** 接資料庫資料 */
export interface IsubTopic {
  action? : "新增" |"修改"| "刪除" 
  ParamType :sourceType |""
  uid :number,
  GroupNo: string ,
  SubNo: number,
  SubName: string ,
  Description: string ,
  Keyword: string ,
  DeleteDate: string 
  UpdateDate :string
}


/** 專家頁面使用  */
export interface IGroupForExpertS {
  Groupno: string;
  Groupname: string;
  City: string;
  Area: string | null;
  Schoolname: string;
  Type: string;
  Class: string;
  FlagOK: boolean;
  ExpOpinion: string;
  ExpMemo: string | null;
  GroupSet: string;
  ExpertName: string;
  Topic :string
  isSelected?:boolean  ;
  groupUID :string ;
  exportAccount :string ;
}

/** DropDown 用的interface  */
export interface  IDropDownGroup {
  name : string 
  DisplayName : string  
  uid? : string  
  key? : string  
  groupNo :string

}

export class dropDownListOption {
  // 將 type 參數賦值給實例的 type 屬性
  constructor( coltype: '專家' | '隊伍' ,groupItem :IGroupForExpertS) {
    this.coltype =coltype ;
    this.DisplayName = `${groupItem.GroupSet.trim()}-${groupItem.ExpertName.trim()}`
    this.name =groupItem.ExpertName 
    this.expAccount = groupItem.exportAccount 
  }

  getDisplayName() {
    return this.DisplayName
  } 
  coltype :'專家' | '隊伍' |"" =""
  // 其他屬性的聲明
  DisplayName: string =""
  pKey: string=""
  name?: string =""
  No?: string ="";
  expAccount?:string =""
}

export interface dropDownExpo{
  className :string 
  exportList? :{exportNo:string ,exportName:string  }[]  
  
  }


  export interface  subTopicParam {
    GroupNo :string  
    SubName :string 
    Description :string
    Keyword :string   
  }


export  interface IGroupTeacherInfo {
    GroupNo: string;
    TeaNo: string;
    TeaName: string;
    TeaTitle: string;
}


export interface crtlInfo {
  Action: string;
  Control: string;
  StageName: string;
  StageNo: number;
  SubControl: string | null;
  /** 階段起始日 */
  BeginDate : string  ; 
  /** 階段結束日 */ 
  EndDate : string ;
  /** 路徑頁面 */ 
  PageName :string  ;
  Role :string 
  
}

/** API */
export interface TeacherlInfo {
  schoolName: string;
  uid: number;
  GroupNo: string | null;
  TeaNo: string;
  TeaName: string;
  TeaTitle: string;
  TeaCell: string;
  TeaEmail: string;
  Password: string;
  Remark: string;
  ApplyName :string 
}

/** */
export interface Collect {
    /** 23956 */
    CollectID: string          ; // 23956,
    /** 慈濟志工文彩燕 新住民的心靈依靠 */
    CollectNo: string          ; // 1,
    /** 慈濟志工文彩燕 新住民的心靈依靠  */
    Source: string          ; // "慈濟志工文彩燕 新住民的心靈依靠",
    /**  https://reurl.cc/nEKdMn */
    Web: string          ; // "https://reurl.cc/nEKdMn",
    /** 5 */
    Credibility: string          ; // 5,
    /** "因為是有圖文的新聞，也有一篇以上許多相同內容。" */
    Reason: string          ; // "因為是有圖文的新聞，也有一篇以上許多相同內容。"

    SourceUpdateDate :string  ;
    


    action ? :"新增"|"刪除"|"修改"
    [key: string]: any;
}

/** */
export interface Summary{
      SummaryID: string |''  ; 
      SummaryNo?: string  ; 
      Summary: string  ; 
      Collects: Collect[]
} 
