import React, { createContext, useContext, useEffect, useState } from 'react';
// import IPageProps from '../interfaces/page'
import NewsCards from './news/newscards';
import TopNavPage1 from './topNav1';
import MainFuncPage from './mainFunc';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PPTestPage from './pptest';
import PPTestPage1 from './pptest/pptest1';
import FooterPage from './footer';
import PPTestPage2 from './pptest/pptest2';
import PPTestPage3 from './pptest/pptest3';
import PPTestPage4 from './pptest/pptest4';
import DPTestPage from './dptest';

import DPPracticePageWeb from './dptest/dppractice/dppractice-web';
import DPPracticePageMix from './dptest/dppractice/dppractice-mix';
import DPPracticePage from './dptest/dppractice/dppractice';
import DPRefPage from './dptest/dpref';
import LoginPage from './login/login';
import NewStudentPage from './newstudent';
import NewTeacherPage from './newteacher';
import MemoryKoPage from './static/memoryko';
import CooperatePage from './static/cooperate';
import AllNews from './news/allnews';
import LoginGooglePage from './login/login-google';
import EpFunPage from './epfun/epfun';
import EpFunTextPage from './epfun/epfuntext';
import TutSitePage from './static/tutsite';
import EpMainPage from './ep/epMain';
import EpResultPage from './ep/epResult';
import EpExcellentPage from './ep/epExcellent';
import TutorialPage from './tutorial';
import StuLearnRecPage from './students/StuLearnRec';
import AboutPage from './static/about';
import IntoPage from './static/introduction';
import TeacherAdminPage from './teachers/TeacherAdmin';
import TeacherStuMgnPage from './teachers/TeacherStuAdmin';
import TeacherStuLearnPage from './teachers/TeacherStuLearn';
import TeacherStuStatisticPage from './teachers/TeacherStuStatistic';
import TeacherStuLoginStatPage from './teachers/TeacherStuLoginStat';
import FaqPage from './faq';
import ReLoginGooglePage from './login/relogin-google';
import LoginNTPCPage from './login/login-ntpc';
import ReLoginNTPCPage from './login/relogin-ntpc';
import EpLoginPage from './ep/epLoginPage';
import EpNewApply from './ep/epNewApply';
import EpApplyHome from './ep/epApplyHome';
import EpApplyTeacher from './ep/epApplyTeacher';
import EpApplyGroup from './ep/epApplyGroup';
import EpApplyExplore from './ep/epApplyExplore';
import EpStudentHome from './ep/epStudentHome';
import EpStudLevel1 from './ep/epStudLevel1';
import EpTeachrHome from './ep/epTeachHome';
import EpTeachLevel1 from './ep/epTeachLevel1';
import EpExpertHome from './ep/epExpertHome';
import EpExpertGroup from './ep/epExpertGroup';
import EpExpertFeedback from './ep/epExpertFeedback';
import EpExpertOpinion from './ep/epExpertOpinion';
import EpExpertComment from './ep/epExpertComment';
import EPFAQPage from './faq-ep';
import EPAboutPage from './static/epabout';

import { getAuthData, getCurrentCompetInfo } from './ep/tool/helper';
import { IApplyFormData, crtlInfo } from '../interfaces/EpModal';
import ApplySysContext from '../components/sysContext';
import EpStudExpert from './ep/epStudExpert';
import EpStudfeedback from './ep/epStudfeedback';
import EpStudLevel3 from './ep/epStudLevel3';
import EpTeachExpert from './ep/epTeachExpert';
import EpTeachfeedback from './ep/epTeachfeedback';
import EpTeachLevel3 from './ep/epTeachLevel3';
import EpStudResult from './ep/epStudResult';
import EpTeachResult from './ep/epTeachResult';
import EpApplyCompleteCheck from './ep/epApplyCompleteCheck';

export type MyContextType = {
    crtlInfoList: crtlInfo[] | undefined; // 開放時間控制項
    setSharedState?: React.Dispatch<React.SetStateAction<string>>;
    setApplyInfoShare?: React.Dispatch<React.SetStateAction<IApplyFormData>>;
    applyInfoShare?: IApplyFormData;
    setCtrlInfoList: React.Dispatch<React.SetStateAction<crtlInfo[]>>;
    setEProle: React.Dispatch<React.SetStateAction<string>>;
    currentRole?: any | undefined;
    setCurrentRole: React.Dispatch<React.SetStateAction<any | undefined>>;
    isView?: boolean;
    selectDate: string;
    setSelectDate: React.Dispatch<React.SetStateAction<string>>;
};

interface IPageProps {
    crtlInfoList?: any; // 定義所有元件都應該接受的屬性
}

const MyContext = createContext<MyContextType | undefined>(undefined);

const HomePage: React.FunctionComponent<IPageProps> = (props) => {
    const styles = {
        tableContainer: {
            position: 'fixed' as 'fixed',
            bottom: '20px', // 改為距離底部 20px
            right: '20px', // 保持距離右邊 20px
            backgroundColor: '#B9E5E8',
            borderRadius: '8px',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 999,
            overflow: 'auto'
        }
    };
    const [selectedDate, setSelectedDate] = useState('');

    const context = useContext(MyContext);
    useEffect(() => {
        const fetchData = async () => {
   
            try {
                await getCurrentCompetInfo()
                // await getCtrls(sessionStorage.getItem('role') || 'guest'); // 等待取得權限
                setroutesEP(routesEPDeflut);
            } catch (error) {
                console.error('Error loading data:', error);
            }
    
        };
        
        fetchData();
     
    }, []);
    const [showTable, setShowTable] = useState(false);
    useEffect(() => {
        // 定義鍵盤事件處理函數，並指定事件的類型為 KeyboardEvent
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'q') {
                context?.setSelectDate((prev) => {
                    return prev; // 切換狀態
                });
                setShowTable((prev) => {
                    return !prev; // 切換狀態
                });
            }
        };

        // 當組件掛載時，添加事件監聽器
        window.addEventListener('keydown', handleKeyDown);

        // 當組件卸載時，移除事件監聽器
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // 空的依賴數組確保只在組件掛載和卸載時觸發
    const [routesEP, setroutesEP] = useState<any[] | undefined>([]);
    useEffect(() => {
        if (showTable) {
  
        }
    }, [showTable]); // 監聽 showTable 變化


    const getCtrls = async (control: string) => {
        let ctrlInfo = await getAuthData(control);
        // setCtrlInfoList(ctrlInfo);
    };
    const routesEPDeflut = [
        // { path: '/eplogin', Component: EpLoginPage },
        { path: '/epNewPage', Component: EpNewApply },
        //  { path: '/epresult', Component: EpResultPage },
        { path: '/epApplyHome', Component: EpApplyHome },
        { path: '/epApplyTeacher', Component: EpApplyTeacher },
        { path: '/epApplyGroup', Component: EpApplyGroup },
        { path: '/epApplyExplore', Component: EpApplyExplore },
        { path: '/epStudentHome', Component: EpStudentHome },
        { path: '/epTeachHome', Component: EpTeachrHome },
        { path: '/epStudLevel1', Component: EpStudLevel1 },
        { path: '/epTeachLevel1', Component: EpTeachLevel1 },
        { path: '/epFAQ', Component: EPFAQPage },
        { path: '/epabout', Component: EPAboutPage },
        { path: '/epExpertHome', Component: EpExpertHome },
        { path: '/epExpertGroup', Component: EpExpertGroup },
        { path: '/epExpertFeedback', Component: EpExpertFeedback },
        { path: '/epExpertOpinion', Component: EpExpertOpinion },
        { path: '/epExpertComment', Component: EpExpertComment },
        { path: '/epStudExpert', Component: EpStudExpert },
        { path: '/epStudfeedback', Component: EpStudfeedback },
        { path: '/epStudLevel3', Component: EpStudLevel3 },
        { path: '/EpteachExpert', Component: EpTeachExpert },
        { path: '/epTeachfeedback', Component: EpTeachfeedback },
        { path: '/epTeachLevel3', Component: EpTeachLevel3 },
        { path: '/epStudResult', Component: EpStudResult },
        { path: '/epTeachResult', Component: EpTeachResult },
        { path: '/epApplyCompleteCheck', Component: EpApplyCompleteCheck }
    ];

    const [crtlInfoLists, setCtrlInfoList] = useState<crtlInfo[]>([]);
    const [EProle, setEProle] = useState<string>('');
    const [currentRole, setCurrentRole] = useState<any>('');

    const HomePack = () => {
        return (
            <>
                <TopNavPage1 />
                <MainFuncPage />
                <NewsCards />
                <FooterPage />
            </>
        );
    };

    return (
        <>
            <MyContext.Provider
                value={{
                    crtlInfoList: crtlInfoLists,
                    setCtrlInfoList,
                    setEProle,
                    setCurrentRole,
                    currentRole,
                    selectDate: selectedDate,
                    setSelectDate: setSelectedDate
                }}
            >
                 
                <div
                    className="3
            "
                ></div>
                {showTable && (
                    <div className="table-container " style={styles.tableContainer}>
                        {/* <p>📅 選擇日期：</p>
                        <input type="date" value={context?.selectDate} onChange={(e) =>{alert(e.target.value); context?.setSelectDate(e.target.value); alert(context?.selectDate)}} />

                        {context?.selectDate && <p>你選擇的日期是：{context?.selectDate}</p>} */}
                        <table>
                            <thead>
                                <tr>
                                    <th>index</th>
                                    <th>Control</th>
                                    <th>動作</th>
                                    <th>階段名稱</th>
                                    <th>StageNo</th>
                                    <th>開始日</th>
                                    <th>結束日</th>
                                </tr>
                            </thead>
                            <tbody>
                                {crtlInfoLists.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td>{item.Control}</td>
                                        <td>{item.Action}</td>
                                        <td>{item.StageName}</td>
                                        <td>{item.StageNo}</td>
                                        <td>{item.BeginDate}</td>
                                        <td>{item.EndDate}</td>
                                        <td>{item.Role}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <ApplySysContext>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/pptest" element={<PPTestPage />} />
                            <Route path="/pptest1" element={<PPTestPage1 />} />
                            <Route path="/pptest2" element={<PPTestPage2 />} />
                            <Route path="/pptest3" element={<PPTestPage3 />} />
                            <Route path="/pptest4" element={<PPTestPage4 />} />
                            <Route path="/dptest" element={<DPTestPage />} />

                            <Route path="/dppractice-web" element={<DPPracticePageWeb />} />
                            <Route path="/dppractice-mix" element={<DPPracticePageMix />} />
                            <Route path="/dppractice" element={<DPPracticePage />} />
                            <Route path="/dpref" element={<DPRefPage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/login-google" element={<LoginGooglePage />} />
                            <Route path="/relogin-google" element={<ReLoginGooglePage />} />
                            <Route path="/login-ntpc" element={<LoginNTPCPage />} />
                            <Route path="/relogin-ntpc" element={<ReLoginNTPCPage />} />
                            <Route path="/newstudent" element={<NewStudentPage />} />
                            <Route path="/newteacher" element={<NewTeacherPage />} />
                            <Route path="/memoryko" element={<MemoryKoPage />} />
                            <Route path="/cooperate" element={<CooperatePage />} />
                            <Route path="/tutorial" element={<TutorialPage />} />
                            <Route path="/tutsite" element={<TutSitePage />} />
                            <Route path="/allnews" element={<AllNews />} />
                            <Route path="/fundata" element={<EpFunPage />} />
                            <Route path="/funtext" element={<EpFunTextPage />} />
                            <Route path="/eptest" element={<EpMainPage />} />
                            <Route path="/epresult" element={<EpResultPage />} />
                            <Route path="/epexcellent" element={<EpExcellentPage />} />
                            <Route path="/faq" element={<FaqPage />} />
                            <Route path="/stulearnrec" element={<StuLearnRecPage />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route path="/introduction" element={<IntoPage />} />
                            <Route path="/teacheradmin" element={<TeacherAdminPage />} />
                            <Route path="/teacherstumgn" element={<TeacherStuMgnPage />} />
                            <Route path="/teacherstulearn" element={<TeacherStuLearnPage />} />
                            <Route path="/teacherstustat" element={<TeacherStuStatisticPage />} />
                            <Route path="/teacherstulogin" element={<TeacherStuLoginStatPage />} />

                            <Route path="/" element={<HomePack />} />
                            <Route path="/eplogin" element={<EpLoginPage />} />
                            {true && routesEP?.map(({ path, Component }) => <Route key={path} path={path} element={<Component />} />)}
                        </Routes>
                    </BrowserRouter>
                </ApplySysContext>
            </MyContext.Provider>
        </>
    );
};

export default HomePage;
export { MyContext };
