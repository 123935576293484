import { useEffect, useContext, useState } from "react";
import { getAuthData } from "../tool/helper";
import { MyContext } from "../../home";

/**
 * @param {string} pageName_ - 當前頁面的名稱，用於檢查和控制相關邏輯。
 * @returns {boolean | null} - `true` 表示授權, `false` 表示未授權, `null` 表示仍在檢查權限。
 */
export const useControlCheck = (pageName_: string): boolean | null => {
  const context = useContext(MyContext);
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null); // 初始值為 null 代表正在載入

  useEffect(() => {
    const fetchControls = async () => {
      const role = sessionStorage.getItem("role") || "guest";
      try {
        const ctrlInfo = await getAuthData(role);
        const isFound = ctrlInfo.some((x: any) => x.Control.trim() === pageName_);
        debugger
        if (isFound) {
          context?.setCtrlInfoList(ctrlInfo); // 更新 Context
        }

        setIsAuthorized(isFound); // 設定權限結果
      } catch (error) {
        console.error("權限檢查錯誤:", error);
        setIsAuthorized(false); // 若發生錯誤，預設沒有權限
      }
    };

    if (pageName_) {
      setIsAuthorized(null); // 重新檢查時，先將狀態設為 `null` (載入中)
      fetchControls();
    }
  }, [pageName_]); // 當 pageName_ 變化時重新執行

  return isAuthorized; // 回傳 `true` / `false` / `null`
};
