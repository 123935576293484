import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo } from '../../services/EP/EpApplyStudentService';
import { currentClass, getLoginInfo, loadCtrlAndDisable, setSessionStudentDisplayInfo } from './tool/helper';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import Schedule from './component/schedule';
import ScheduleDB from './component/scheduleDB';

export interface StudentDisplay {
    ApplyName: string;
    ApplyNo: string;
    ApplyTitle: string;
    GroupName: string;
    GroupNo: string;
    SchoolName: string; //"國語實小        "
    StuName: string; //"趙小一       "
    StuNo: string; //  "FA001-01-1"
    TeaName: string; // "李老師       "
    TeaNo: string; //""
    TeaTitle: string;
    Team: { TStuName: string; TStuNo: string; checked: boolean }[];
    Teachers: {
        TeaNo: string;
        TeaName: string;
        TeaTitle: string;
    }[];
}

// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpStudentHome: React.FunctionComponent<IPageProps> = () => {
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    const [studentInfo, setStudentInfo] = useState<StudentDisplay>();

    /** 設定日期 */
    useEffect(() => {
        loadCtrlAndDisable(sessionStorage.getItem('role') || 'guest', '首頁');
    }, []);


    useEffect(() => {
        fetchdataStudentInfo();
    }, []);

    /** */
    const fetchdataStudentInfo = async () => {
        /** 取得登入資料 */
        try {
            let currentLoginInfo = getLoginInfo();
            setCurrentLoginInfo(currentLoginInfo);
            const studentInfo = (await getDrGroupByStuNoClass(currentClass, currentLoginInfo.LoginID)) as StudentDisplay; //as StudentInfo

            if (studentInfo?.GroupNo) {
                setStudentInfo(studentInfo);
                setSessionStudentDisplayInfo(studentInfo);
            }
        } catch (ex) {
            alert('取得學生資料發生錯誤! ');
            console.log('取得學生資料發生錯誤! ', ex);
        }
    };

    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <StudentFunctionBar />
                    </div>
                    <div className="text-center h5 mt-4">歡迎參加 {currentClass}柯華葳線上數位閱讀專題探究競賽</div>
                    <div className="flex-parent mt-5">
                        <ScheduleDB />
                        <div className="pt-3 mt-4"></div>
                    </div>

                    <div className="text-center fw-bolder text-muted fs-18 mb-4">
                        {' '}
                        {studentInfo?.SchoolName} {studentInfo?.GroupName} 隊 ({studentInfo?.StuNo})
                    </div>
                    <div className="fs-18">
                        <div className="card mx-auto p-4" style={{ maxWidth: '800px' }}>
                            <div className="text-dblue">
                                你(妳)的學生編號資料由
                                <span className="text-oblue fw-bolder">
                                    {studentInfo?.ApplyTitle}
                                    {studentInfo?.ApplyName}
                                </span>
                                管理。
                            </div>
                            <div className="text-dblue my-3">
                                指導老師為<span className="text-oblue fw-bolder">{studentInfo?.Teachers.map((teacher) => teacher.TeaName)}</span> 老師。
                            </div>
                            <div className="text-dblue">
                                在探究活動期間，可以和
                                <span className="text-oblue fw-bolder">
                                    {studentInfo?.Team?.map((student) => (
                                        <span> {student.TStuName.trim()}同學 </span>
                                    ))}
                                </span>
                                合作，共同完成探究任務。
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <img src="./assets/img/cheer_up2.png" alt="" className="" />
                    </div>

                    <div className="pt-3 mt-4"></div>
                </div>
            </div>
            <FooterPage />
        </>
    );
};

export default EpStudentHome;
