import React, { useContext, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import TopNavPage1 from '../topNav1';
import ApplyFunctionBar from './component/applyFunctionBar';
import { currentClass, disableElement, getLoginInfo, validateSubmit } from './tool/helper';
import {
    createDrGroupByYrApplyNo,
    createDrTeaGroupByTeaNoGroup,
    deleteDrTeaGroupByGroupNo,
    getDrGroupPrimeByYrApplyNoWithDetail,
    getDrStudentByGroup,
    getDrTeaGroupByGroupNo,
    updateDrApplyCompleteByNo,
    updateDrGroupPrimeByYrGroupNo,
} from '../../services/EP/EPApplyGroupService';
import { IGroupTeacherInfo, IApplyFormData, IGroupInfo, IGroupStudent, IInsertApplyGroup, IUpdateApplyGroup, IUpdateApplyStudent, IInsertApplyStudent } from '../../interfaces/EpModal';
import { getEPApplyByNo } from '../../services/EP/EpLoginService';

import { MyContext, MyContextType } from '../home';
import StepGuide from './component/StepGuide';
import NoEntry from './tool/NoEntry';
import { useControlCheck } from './HOC/withControlCheck';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const EpApplyCompleteCheck: React.FunctionComponent<IPageProps> = (props) => {
    const pageName = '報名完成確認';
    const [openErrModal, setOpenErrModal] = useState(false);
    /**
     * 群組資料列表
     * 用於存儲所有群組的資訊
     */
    const [groupDataList, setGroupDataList] = useState<IGroupInfo[]>([]);
    const [applyInfo, setApplyInfo] = useState<IApplyFormData>();
    /** 目前選得隊伍 */
    const [selectedGroup, setSelectedGroup] = useState<IGroupInfo>();
    /** 目前選擇的隊伍明細 */
    const [currentGroupStudentList, setCurrentGroupStudentList] = useState<IGroupStudent[]>([]);
    const [groupteacherList, setGroupTeacherList] = useState<IGroupTeacherInfo[]>([]);
    const [selectTeacher1, setSelectTeacher1] = useState<any>('');
    const [selectTeacher2, setSelectTeacher2] = useState<any>('');
    const [isReload, setIsReload] = useState<boolean>();
    const [errMsg, setErrMsg] = useState('');
    const [btndisable, setBtndisable] = useState(false);
    const [errInfo, seterrInfo] = useState('');
    const [errorCount, setErrorCount] = useState(0);
    const [formDataGroup, setFormDataGroup] = useState<IGroupInfo>({
        ApplyNo: '',
        Class: '',
        CompLearn: '',
        FlagOk: '',
        FlagRevOK: '',
        GroupNo: '',
        Groupname: '',
        PptDate: '',
        Remark: '',
        SchoolFull: '',
        Schoolname: '',
        Type: '',
        UploadDate: '',
        uid: '',
        teacher1: '',
        teacher2: ''
    });


 
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();

    let context = useContext(MyContext);
    useEffect(() => {
        checkControl(context, sessionStorage.getItem('role') || 'guest', '小隊基本資料');
    }, []);
    const checkControl = async (context_: MyContextType | undefined, role_: string, homePage_: string) => {
        if (context_?.crtlInfoList?.find((x) => x.Control.trim() === homePage_)?.Action.trim() === 'view') {
            disableElement();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            let groupList = await fetchDataGroup();
            setSelectedGroup(groupList[0]);
        };
        getEPApplyByLoginID(sessionStorage.getItem('LoginID') + '');
        fetchData();
        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }
    }, [isReload]);

    useEffect(() => {
        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }
    }, [selectedGroup]);





    useEffect(() => {
  
        if (currentGroupStudentList.length < 3) {
            seterrInfo(`一個小隊3~4位，目前只有${currentGroupStudentList.length}位成員`);
  
            
        } else {
          
        }
    }, [currentGroupStudentList]);

    /**取得學生資料 */
    const fetchDataGroupStudent = async (groupNo: string) => {
        let groupStudentList = (await getDrStudentByGroup(groupNo)) as IGroupStudent[];
        setCurrentGroupStudentList(groupStudentList);

        // 取小隊指導老師
        let groupTeachers = (await getDrTeaGroupByGroupNo(groupNo)) as IGroupTeacherInfo[];
        setGroupTeacherList(groupTeachers);
    };

    /**  取資料 */
    const fetchDataGroup = async (): Promise<IGroupInfo[]> => {
        let currentLoginInfo = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo);
        // let GroupDataList = (await getDrGroupPrimeByYrApplyNo(currentClass, currentLoginInfo.LoginID)) as IGroupInfo[];
        let GroupDataList = (await getDrGroupPrimeByYrApplyNoWithDetail(currentClass, currentLoginInfo.LoginID)) as IGroupInfo[];

       
        // 初始化錯誤計數
        let errorTotal = 0;
        
        // 遍歷 GroupDataList，檢查 TeacherCount 和 StudentCount
        GroupDataList.forEach(group => {
            if (validateRsInfo(group.TeacherCount, group.StudentCount)) {
                errorTotal += 1; // 如果有錯誤則累加
            }
        });
        
        // 更新 errorCount
        setErrorCount(errorTotal);
        

        setGroupDataList(GroupDataList);
        return GroupDataList;
    };

    /** 取得登入者基本資訊 (申請者)*/
    const getEPApplyByLoginID = async (loginID: string) => {
        let rsp = await getEPApplyByNo(loginID);
        // alert(JSON.stringify(rsp))
        setApplyInfo(rsp[0] as IApplyFormData);
    };

   

    /**  */
    useEffect(() => {
        groupDataList.forEach((group: IGroupInfo) => {});
    }, [groupDataList]);
    /** 刪除小隊資料 */

    const updateComplete = async () => {
        if (applyInfo?.ApplyNo) {
            try {
                await updateDrApplyCompleteByNo(applyInfo?.ApplyNo);
                alert('確認完成報名');
            } catch (ex) {
                alert('發生錯誤' + JSON.stringify(ex));
            }
        }
    };

    const validateRsInfo = (teacherCount: number = 0, studentCount: number = 0) => {
        let result = '';
        if (!teacherCount) {
            result += '教師至少需要一位 ';
           
        }

        if (studentCount > 4 || studentCount < 3) {
            result += '小隊成員需3-4位 ';
        
        }

        return result;
    };
    /** 打開 */
    const isView = useControlCheck('小隊基本資料');
    if (!isView) {
        return <NoEntry />;
    }

    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <ApplyFunctionBar crtlInfoList={context?.crtlInfoList} />
                    </div>
                    <StepGuide currentStep={pageName} />
                    <div className="text-center h5 mt-4">報名資料確認</div>
                    <div className="text-muted fs-18 fw-bold mb-3"></div>
    <ol>
    <li>「檢查說明」若有紅字訊息，請補足資料。</li>
    <li>所有小隊資料檢查無誤後，「報名確認」按鈕才會有效。</li>
    <li>「報名確認」無誤後，若有調整學生或指導老師資料，請再按「報名確認」。</li>
</ol>



                    <hr className="horizontal dark" />
                    <div className="d-flex flex-wrap mb-3 mt-4">
                        <div className="text-muted fs-18 fw-bold">
                            {applyInfo?.SchoolName} 承辦人: {applyInfo?.ApplyName} ({applyInfo?.ApplyNo})
                        </div>
                    </div>
                    <div className="card p-4 pt-3">
                        <div className="table-responsive pt-3">
                            <table className="text-sm table table-striped mb-0">
                                <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                        <th className="p-2 text-nowrap">序號</th>
                                        <th className="p-2 text-nowrap">小隊編號</th>
                                        <th className="p-2 text-nowrap">小隊名稱</th>
                                        <th className="p-2 text-nowrap">競賽/學習</th>
                                        {/* <th className="p-2 text-nowrap">報名審核OK</th> */}
                                        {/* <th className="p-2 text-nowrap">完成成果報告</th> */}
                                        <th className="p-2 text-nowrap"> 老師</th>
                                        <th className="p-2 text-nowrap">學生</th>

                                        {/* <th className="p-2 text-nowrap">備註</th> */}
                                        <th className="p-2 text-nowrap">檢查說明</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupDataList.map((group, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{group.GroupNo}</td>
                                            <td>{group.Groupname}</td>

                                            <td>{group.CompLearn}</td>
                                            {/* <td>{group.FlagOk}</td> */}
                                            {/* <td>
                                                {formatDate(group.UploadDate)}
                                            </td> */}
                                            <td>{group.TeaNames}</td>
                                            <td>{group.StuNames}</td>

                                            <td>
                                                <a
                                                    href={`/epApplyGroup`}
                                                    style={{
                                                        color: validateRsInfo(group.TeacherCount, group.StudentCount) ? 'red' : 'black',
                                                        textDecoration: 'none' // 移除預設的底線
                                                    }}
                                                >
                                                    {validateRsInfo(group.TeacherCount, group.StudentCount)}
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr className="horizontal dark" />

                    <div className="flex-wrap mb-3 mt-4 justify-content-between">
                        <div
                            className="btn text-second bg-second pointer mb-3 ms-auto"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pointerEvents: !errorCount ? 'auto' : 'none',
                                opacity: !errorCount ? 1 : 0.5
                            }}
                            onClick={() => !errorCount && updateComplete()} // 只有 allPass === true 時才執行
                        >
  
                            <span>報名確認</span>
                        </div>

                        <div className="text-second mt-3"></div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default EpApplyCompleteCheck;
