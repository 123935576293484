import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createSubCollectByGroupSubSumNo, createSubSummaryByGroupNoSubNo, deleteSubCollectByUid, updateSubCollectByUid, updateSubSummaryByUid } from '../../../services/EP/OpinionService';
import { Collect, Summary } from '../../../interfaces/EpModal';

interface IProp {
  ishModalOpen: boolean;
  setIsModalOpen: (a: boolean) => void;
  modeProp: '新增' | '編輯' | ''
  Summary?: Summary
  subNo?: string
  groupNo?: string
  onFinish: () => void

}

/**SummaryModal */
const SummaryModal: React.FunctionComponent<IProp> = ({ ishModalOpen, onFinish, setIsModalOpen, Summary, groupNo, modeProp: modeProp, subNo }) => {

  const [formData, setFormData] = useState<Summary>({} as Summary);


  useEffect(() => {
    if (modeProp === '編輯' && Summary) {
      setFormData(Summary)

    } else if (modeProp === '新增' && subNo) {
      setFormData({
        Summary: '', Collects: [{
          CollectID: "",
          CollectNo: "",
          Source: "",
          Web: "",
          Credibility: "",
          Reason: "",
          SourceUpdateDate: ""
        }]
      } as Summary)
    }

  }, [ishModalOpen])


  /** 儲存 */
  const saveEditModal = async () => { 
    if (modeProp === '新增' && groupNo && subNo) {
      let param = {
        Summary: formData.Summary
      }
      try {
        let rsp = await createSubSummaryByGroupNoSubNo(param, groupNo, subNo) as any
        const summaryUid = rsp[0].SummaryNo


        if (formData.Collects) {
          for (const collect of formData.Collects) {
            if (groupNo && subNo && summaryUid) {
              await createSubCollectByGroupSubSumNo(collect, groupNo, subNo, summaryUid);
            }
          }
        }
        onFinish()
        setIsModalOpen(false)
      } catch (ex) {
        alert('新增發生錯誤!' + JSON.stringify(ex))
      }
    }

    if (modeProp === '編輯' &&formData.SummaryID) {
      let paramSum = {
        Summary: formData.Summary
      }
      await updateSubSummaryByUid(paramSum, formData.SummaryID)
      try {
        // 一筆一筆新增
        for (const collect of formData.Collects) {

          if (groupNo && subNo) { // 新稱
            try {
              if (collect.action === "刪除" && collect.CollectID) { // 刪除項目

                await deleteSubCollectByUid(collect.CollectID)

              } else if (collect.action === "新增") {

                if (groupNo && subNo && formData.SummaryNo) {
                  await createSubCollectByGroupSubSumNo(collect, groupNo, subNo, formData.SummaryNo);
                }

              } else  { // 修改
                await updateSubCollectByUid(collect, collect.CollectID)
              }

              alert("修改成功!")
              onFinish()
              setIsModalOpen(false)
            } catch (ex) {
              alert('新增重點整理發生錯誤:' + JSON.stringify(ex))

            }
          }
        }
      } catch (ex) {
        alert('新增發生錯誤!')
      }
    }
  }

  /** 當設定畫面有改變時*/
  const setCollectOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, obj: Collect, attr: string) => {
    obj[attr] = e.target.value
    setFormData({ ...formData })
  };

  const removeCollects = (item: Collect) => {
    item.action = "刪除"
    setFormData({ ...formData })

  }

  /** 新增summury */
  const addNewCollect = () => {
    formData.Collects.push({
      CollectID: "",
      CollectNo: "",
      Source: "",
      Web: "",
      Credibility: "",
      Reason: "",
      SourceUpdateDate: "",
      action: "新增"
    })
    setFormData({ ...formData })
  }

  return (
    <>
      <Modal show={ishModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header style={{ maxWidth: '800px' }}>

          <Modal.Title>
            <h5 className="modal-title">{modeProp}重點整理與資料來源</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" px-3 pb-4">
          <div>
            <form role="form text-left">
              <div className="row">
                <div className="col-12">
                  <label className="">重點整理</label>


                  {/* {JSON.stringify(formData)} */}
                  <div className="d-flex align-items-center mb-3">

                    <textarea className="form-control" rows={5}
                      onChange={(e) => { setFormData({ ...formData, Summary: e.target.value }) }} value={formData.Summary}
                      placeholder="條列式，敘述重點即可，不超過200字">
                    </textarea>
                  </div>



                </div>
                <div className="col-12">
                  <label className="mt-3">資料來源 ({formData.Collects?.length})</label>
                  {formData.Collects?.map((collect, index) => {

                    if (collect.action === "刪除") {
                      return (<></>)

                    }
                    return (

                      <div className="d-flex align-items-center mb-3">
                        <div className="text-nowrap me-3">({index + 1})</div>
                        <div className="w-100 bg-form-coll p-3">
                          <div className="d-flex align-items-center mb-3">
                            <div className="text-nowrap me-2">網名</div>
                            <input type="text" className="form-control" placeholder=""
                              onChange={(e) => setCollectOnChange(e, collect, 'Source')}
                              value={collect.Source} />
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="text-nowrap me-2">網址</div>
                            <input type="text"
                              className="form-control"
                              placeholder=""
                              value={collect.Web}
                              onChange={(e) => setCollectOnChange(e, collect, 'Web')} />
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="text-nowrap me-2">可信度</div>
                            <select className="form-control" name="" id="" onChange={(e) => setCollectOnChange(e, collect, 'Credibility')}>
                              <option value="0"></option>
                              <option value="1" selected={collect.Credibility === '1'}>★</option>
                              <option value="2" selected={collect.Credibility === '2'} >★★</option>
                              <option value="3" selected={collect.Credibility === '3'} >★★★</option>
                              <option value="4" selected={collect.Credibility === '4'}>★★★★</option>
                              <option value="5" selected={collect.Credibility === '5'}>★★★★★</option>
                            </select>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="text-nowrap me-2">可信理由</div>
                          <textarea
                            className="form-control"
                            placeholder=""
                            value={collect.Reason}
                            onChange={(e) => setCollectOnChange(e, collect, 'Reason')}
                            rows={1}
                          ></textarea>
                          </div>
                        </div>
                        <div className="material-icons text-dark pointer ms-3" onClick={() => { removeCollects(collect) }}
                          title="刪除">
                          delete_outline</div>
                      </div>

                    )
                  })}

                  {
                    (<>                    <div className="d-flex align-items-center mb-3">
                      <div className="w-100  p-3" ></div>
                      <div className="material-icons text-dark pointer ms-3 float-right" onClick={() => { addNewCollect() }}

                        title="新增"
                      >
                        add_circle_outline</div>
                    </div>
                    </>)
                  }
             

                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsModalOpen(false)}>
            取消
          </button>
          <button type="button" className="btn btn-primary" onClick={() => saveEditModal()}>
            儲存
          </button>

        </Modal.Footer>
      </Modal>

    </>
  );
};
export default SummaryModal;
