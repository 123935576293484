import { useEffect, useState } from 'react';
import { getExSchoolAreaByCity, getExSchoolByCityArea, getExSchoolCity } from '../../../services/EP/EpApplyHomeService';
import { devTolPrint } from '../tool/helper';

export interface SchoolInfo {
    SchoolNo: string;
    SchoolName2: string;
    SchoolName: string;
    SchNo: number;
    SchName: string;
    Address: string;
    Tel: string;
    Type: string;
    Type2:string // 公立私立
}

interface SchoolSelectProps {
    selectCity: string;
    setSelectCity: (city: string) => void;
    selectArea: string;
    setSelectArea: (area: string) => void;
    selectSchool: SchoolInfo | undefined;
    setSelectSchool: (school: SchoolInfo | undefined) => void;
    reset: boolean; // 新增：當 `reset` 變為 true，清空 `SchoolSelect`
    schoolType: string; // **接收 schoolType**
}

const SchoolSelect: React.FC<SchoolSelectProps> = ({
    selectCity,
    setSelectCity,
    selectArea,
    setSelectArea,
    selectSchool,
    setSelectSchool,
    reset, // 父元件傳來的 reset 狀態
    schoolType // **接收 schoolType**
}) => {
    const [cityList, setCityList] = useState<{ City: string }[]>([]);
    const [areaList, setAreaList] = useState<{ Area: string }[]>([]);
    const [schoolList, setSchoolList] = useState<SchoolInfo[]>([]);
    const arrCity = [
        { City: '臺北市' },
        { City: '基隆市' },
        { City: '新北市' },
        { City: '桃園市' },
        { City: '新竹市' },
        { City: '新竹縣' },
        { City: '苗栗縣' },
        { City: '南投縣' },
        { City: '臺中市' },
        { City: '彰化縣' },
        { City: '雲林縣' },
        { City: '嘉義市' },
        { City: '嘉義縣' },
        { City: '臺南市' },
        { City: '高雄市' },
        { City: '屏東縣' },
        { City: '宜蘭縣' },
        { City: '花蓮縣' },
        { City: '臺東縣' },
        { City: '澎湖縣' },
        { City: '金門縣' },
        { City: '連江縣' }
    ];
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            let rsp = (await getExSchoolCity()) as { City: string }[];
            setCityList(arrCity);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (selectCity) {
            getExSchoolAreaByCitys(selectCity);
        } else {
            setAreaList([]); // 清空地區選單
        }
    }, [selectCity]);

    const getExSchoolAreaByCitys = async (selectCity: string) => {
        let rsp = (await getExSchoolAreaByCity(selectCity)) as { Area: string }[];
        setAreaList(rsp);
    };

    useEffect(() => {
        if (selectCity && selectArea) {
            getExSchoolByCityAreas(schoolType);
        } else {
            setSchoolList([]); // 清空學校選單
        }
    }, [selectCity, selectArea, schoolType]);

    const getExSchoolByCityAreas = async (schoolType: string) => {
        let result: SchoolInfo[] = [];
        let rsp = (await getExSchoolByCityArea(selectCity, selectArea)) as SchoolInfo[];
        result = rsp.filter((x) => x.Type === schoolType);
        setSchoolList(result);
    };

    const handleSchoolChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = schoolList.find((x) => x.SchoolName === e.target.value);
        if (selected) {
            setSelectSchool(selected);
        }
    };

    // **監聽 `reset` 變化，清空所有選擇**
    useEffect(() => {
        if (reset) {
            setSelectCity(''); // 清空選擇的縣市
            setSelectArea(''); // 清空選擇的地區
            setSelectSchool(undefined); // 清空選擇的學校
        }
    }, [reset]); // 只在 `reset` 變更時執行

    return (
        <div className="d-flex gap-3">
            <select className="form-control fs-16" value={selectCity} onChange={(e) => setSelectCity(e.target.value)}>
                <option value="">選擇縣市</option>
                {cityList.map((item) => (
                    <option key={item.City} value={item.City}>
                        {item.City}
                    </option>
                ))}
            </select>

            <select className="form-control fs-16" value={selectArea} onChange={(e) => setSelectArea(e.target.value)}>
                <option value="">選擇地區</option>
                {areaList.map((item) => (
                    <option key={item.Area} value={item.Area}>
                        {item.Area}
                    </option>
                ))}
            </select>

            <select className="form-control fs-16" value={selectSchool?.SchoolName || ''} onChange={handleSchoolChange}>
                <option value="">選擇學校</option>
                {schoolList.map((item) => (
                    <option key={item.SchoolName} value={item.SchoolName}>
                        {item.SchoolName}
                    </option>
                ))}
            </select>

            {/* {devTolPrint(selectSchool)} */}
        </div>
    );
};

export default SchoolSelect;
