import React, { useEffect, useState } from 'react'
import { NewsModal } from '../../components/modal/newsmodal';
import IAdNews from '../../interfaces/AdNews';
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils';
import { getAdNews } from '../../services/AdNewsService';
import { set } from 'date-fns';

const NewsCards: React.FunctionComponent<IPageProps> = props => {
    const [adNews,setAdNews]=useState<IAdNews[]>([])
    const [currIdx,setCurrIdx]=useState(0);
    const [openModal,setOpenModal]=useState(false);
    const [currentNews,setCurrentNews]=useState<IAdNews>({} as IAdNews);

    const openNews=(idx:number)=>{
      setCurrentNews(adNews[idx]);
      setCurrIdx(idx);
      setOpenModal(true);
    }

    const resetModal=()=>{
      let modalBody=document.getElementById('modalBody');
      if(modalBody!==null) modalBody.innerHTML='';
    }

    useEffect(()=>{
      getAdNews('',1).then(data=>{
        setAdNews(data);
      })
    },[])

   /* useEffect(()=>{
      if(adNews.length>0)
      {
        if(openModal)
        {
          let modalBody=document.getElementById('modalBody');
          if(modalBody!==null) modalBody.innerHTML=adNews[currIdx].Context!;
          setOpenModal(false);
        }
      }
    },[openModal])*/


    return (
      <>
        <div className="section">
          <div className="container">
              <div className="d-flex align-items-center">
                <h5 className="title">最新消息<small className="ms-2">NEWS</small></h5>
                <a className="btn btn-outline-secondary btn-round ms-auto" onClick={()=>handleLink('/allnews')}>更多消息</a>
              </div>            
              <div className="row">
                {
                  adNews.map((data,i)=>{
                    return <div className="col-12 col-md-4 mb-3" key={i}>
                              <div className="card pointer h-100" data-bs-toggle="modal" data-bs-target="#newsModal">
                                <div className="card-body">
                                  <div className="card-title d-flex mb-2">
                                    {(data.TopNews==="1") ?  <i className="fas fa-thumbtack text-warning me-2"></i> :
                                    <i className="fas fa-thumbtack text-gray transform-rotate pointer me-2"></i>
                                    }
                                    <small className="text-muted">{data.SendDate}</small>
                                  </div>
                                  <p className="news-title mb-0">
                                    {data.ShortContext}
                                  </p>
                                </div>
                                <div className="card-footer text-end pt-0">
                                  <button type="button" className="btn btn-link mb-0" data-bs-dismiss="modal" onClick={()=>openNews(i)}>
                                    繼續閱讀
                                    <i className="fas fa-angle-right"></i>
                                  </button>
                                </div>
                              </div>    
                            </div>
                  })
                }
              </div>
          </div>
      </div>  
      {/*<div className="modal fade" id="newsModal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">最新消息</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <small className="text-muted">{(adNews.length!==0)?adNews[currIdx].SendDate:''}</small>
              <p id='modalBody'></p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>resetModal()}>關閉</button>
            </div>
          </div>
        </div>
              </div> */}             
      <NewsModal title="最新消息" isOpen={openModal} setOpenModal={setOpenModal} currentNews={currentNews}/>
      </>
    );
}

export default NewsCards;

