import { useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { getDrScheduleByYr, getNowTime } from '../../../services/EP/EpScheduleService';
import { currentClass } from '../tool/helper';


interface CompetitionStep {
  Class: string;
  SchNo: number;
  SchName: string;
  MileStone: boolean;
  BeginDate: string;
  EndDate: string;
}

const ScheduleDB: React.FunctionComponent<IPageProps> = () => {
  const [nowTime, setNowTime] = useState<string | null>(null);
  const [schedule, setSchedule] = useState<CompetitionStep[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const nowTimeFromAPI = await getNowTime() as any;
      setNowTime(nowTimeFromAPI[0].nowTime);

      const scheduleFromAPI = await getDrScheduleByYr(currentClass) as any;
      setSchedule(scheduleFromAPI);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!nowTime || schedule.length === 0) return <>日程表資料未載入</>

  const currentDate = new Date(nowTime);

  // 格式化日期範圍（`M/D` 或 `M/D~M/D`）
  const formatDateRange = (begin: string, end: string): string => {
    const beginDate = new Date(begin);
    const endDate = new Date(end);
    const format = (date: Date) => `${date.getMonth() + 1}/${date.getDate()}`;
    return begin === end ? format(beginDate) : `${format(beginDate)}~${format(endDate)}`;
  };

  // 判斷當前步驟（`nowTime` 在 `BeginDate` 和 `EndDate` 之間就高亮）
  const isCurrentStep = (begin: string, end: string): boolean => {
    const beginDate = new Date(begin);
    const endDate = new Date(end);
    return currentDate >= beginDate && currentDate <= endDate;
  };

  return (
    <div className="flex-parent mt-5">
      <style>
        {`
          .input-flex-container.timelined .input:last-child::after {
            display: none !important;
          }
        `}
      </style>
      <div className="input-flex-container timelined">
        {/* "Ready Go" 初始標記 */}
        <div className="input text-black " id="readyGo">
          <span data-year="" data-info="Ready Go!"></span>
        </div>

        {/* 渲染時間軸 */}
        {schedule.map((step, index) => (
          <div
            key={step.SchNo}
            className={`input text-white fw-bolder text-center ${
              isCurrentStep(step.BeginDate, step.EndDate) ? "bg-warning text-dark" : ""
            }`}
            style={{
              background: "#025224",
              borderRight: index === schedule.length - 1 ? "2px solid transparent" : "0px solid white", // **確保最後一條線不顯示但保留空間**
              position: "relative",
            }}
          >
            {step.SchNo}
            <span data-year={formatDateRange(step.BeginDate, step.EndDate)} data-info={step.SchName}></span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleDB;
