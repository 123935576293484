import axios from 'axios'
import  config from '../../config/config';



export const getDrScheduleByYr =async (class_year :string)=>{
      const url=config.server.url+`/api/DrSchedule/yr/${class_year}`
      return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  }


  export const getNowTime =async ()=>{
    const url=config.server.url+`/api/DrSchedule/nowTime`
    return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });

}



/**取得目前的競賽資訊 */
export const getCurrentCompetitionInfo =async ()=>{
  const url=config.server.url+`/api/DrTable/actclass`
  return new Promise((resolve, reject) => {
      axios.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          reject(error); //
        });
    });

}


// /api/AdNews/class

export const getAdNewByClass =async (className:string)=>{
  const url=config.server.url+`/api/AdNews/class/${className}`
  return new Promise((resolve, reject) => {
      axios.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          reject(error); //
        });
    });

}




