import React, { useContext, useEffect, useState } from 'react';

import { handleLink } from '../../../modules/utils';
import { crtlInfo } from '../../../interfaces/EpModal';
import { getAuthData, logOut } from '../tool/helper';
import { MyContext } from '../../home';



interface IProp {
    currentPage? :string 
 
}

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const ExpertFunctionBar: React.FunctionComponent<IProp> = (props) => {
    // const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[]>([]);

    useEffect(() => {
        getCtrls(sessionStorage.getItem('role') || '');

    }, []);

    const context = useContext(MyContext);
    const getCtrls = async (control: string) => {
        let ctrlInfo = await getAuthData(control);
        console.log(ctrlInfo)
        context?.setCtrlInfoList(ctrlInfo);
    };
    const getCtrl = (CtrlName: string) => {
        let result;
        if (context?.crtlInfoList?.length) {
          
            result = context?.crtlInfoList?.find((x) => {
               
                return x.Control.trim() === CtrlName.trim();
            });
            
        }

        // alert(CtrlName+result)
        context?.setCurrentRole(result);
      
        return result;
    };

    const link = (ctrl: string ,route:string) => {
        if (getCtrl(ctrl)) {
            handleLink(route);
        } else {

        }
    };
    const disableStyle = {
        color: 'gray',
        background: '#dddddd'
        
    };

    return (
        <>
            <div className="text-center text-lg-end">
                <a
                  href={getCtrl('專家學者首頁') ? '/epExpertHome' : undefined}
                    onClick={() => {
                        link('專家學者首頁','/epExpertHome');
                    }}
                    style={getCtrl('專家學者首頁') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 "
                    type="button"
                
                >
                    專家學者首頁  
                </a>
                <a
                    href={getCtrl('小隊基本資料') ? '/epExpertGroup' : undefined}
                    onClick={() => {
                        link('小隊基本資料','/epExpertGroup');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('小隊基本資料') ? {} :disableStyle }
                    type="button"
                >
                    小隊基本資料
                </a>
                <a
                    href={getCtrl('探究(回饋前)') ? '/epExpertFeedback' : undefined}
                    onClick={() => {
                        link('探究(回饋前)','/epExpertFeedback');
                    }}
                    
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('探究(回饋前)') ? {} :disableStyle }
                    type="button"
                >
                    探究(回饋前)
                </a>
                <a
                    href={getCtrl('回饋意見') ? '/epExpertOpinion' : undefined}
                    onClick={() => {
                        link('回饋意見','/epExpertOpinion');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('回饋意見') ? {} :disableStyle }
                    type="button"
                >
                    回饋意見
                </a>
                <a
                    href={getCtrl('參考評語') ? '/epExpertComment' : undefined}
                    onClick={() => {
                        link('參考評語','/epExpertComment');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('參考評語') ? {} :disableStyle }
                    type="button"
                >
                    參考評語
                </a>

                <a
                    // href="epApplyLogin.html"
                    onClick={() => {
                        logOut();
                    }}
                 
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
            </div>
        </>
    );
};
export default ExpertFunctionBar;
