import React, { useEffect, useState } from 'react'
import { IAdFaq } from '../interfaces/AdFaq';
import IPageProps from '../interfaces/page'
import { handleLink } from '../modules/utils';
import { getAdFaq, getAdFaqByKeyword } from '../services/AdFaqService';
import FooterPage from './footer';
import TopNavPage1 from './topNav1';

const EPFAQPage: React.FunctionComponent<IPageProps> = props => {
    const [adFaq,setAdFaq]=useState<IAdFaq[]>([])
    const [isFetched,setIsFetched]=useState(false);
    let typeidx=0
    useEffect(()=>{
        const fetchData=async()=>{
            const response=await getAdFaq('專題競賽');
            setAdFaq(response);
            setIsFetched(true);
        }
        fetchData();
    },[])

    
    if(isFetched)
    {
    return (
        <>
            <TopNavPage1/>
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <h5 className="title mb-3 me-3">線上探究<small className="ms-2">EP,Explore Process</small></h5>
                        <h5 className="order-2 order-md-1 mb-3">EP 常見問題</h5>
                        <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{width:'232px'}}>
                            <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink("/eptest")}>
                                <i className="fas fa-chevron-left"></i>
                                返回 EP 線上探究
                            </button>
                        </div>
                    </div>
                    <div className="accordion mt-4">
                        <div className="card p-2">
                            {
                                adFaq.map((item,index)=>{
                                    if((index===0) || (item.Type!==adFaq[index-1].Type))
                                    {
                                        typeidx++;
                                        return <>
                                                    <div className="alert alert-blue mb-0">
                                                        <button className="accordion-button p-0" type="button" data-bs-toggle="collapse" data-bs-target={"#level"+typeidx.toString().trim()}
                                                        aria-expanded="true" aria-controls={"level"+typeidx.toString().trim()}>
                                                        <div style={{color:'#116e71'}}>{item.Type}</div>
                                                        <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                        <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                    <div className={"pt-3 collapse show"} id={"level"+typeidx.toString().trim()} data-bs-parent="#accordionRental">
                                                        <div className="qaq">
                                                            <span>Q.{index+1}{item.Question}</span>
                                                        </div>
                                                        <div className="qaa text-start">
                                                            {item.Answer}
                                                        </div>
                                                    </div>
                                                </>                                        
                                    }
                                    else
                                    {
                                    return <div className="pt-3 collapse show" id={"level"+(typeidx).toString().trim()} data-bs-parent="#accordionRental">
                                                <div className="qaq">
                                                    <span>Q.{index+1}{item.Question}</span>
                                                </div>
                                                <div className="qaa text-start">
                                                    {item.Answer}
                                                </div>
                                    
                                            </div>
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <FooterPage/>
        </>
        
    );
    }
    else
    {
        return <div><h3>Loading</h3></div>;
    }
}

export default EPFAQPage;