import React, { useEffect, useState } from 'react'
import { IEPSchedule } from '../../interfaces/EPSchedule';
import IPageProps from '../../interfaces/page'
import FooterPage from '../footer';
import TopNavPage1 from '../topNav1';

import { ObjectModal } from '../../components/modal/objectmodal';
import { handleLink } from '../../modules/utils';
import { checkFileExists } from '../../services/DrFileService';
import { announcementClassName, currentClass } from './tool/helper';
import ScheduleDB from './component/scheduleDB';
import { getAdNewByClass } from '../../services/EP/EpScheduleService';
import ContactModal from './tool/contactModal';
interface NewsItem {
    Context: string;  // 內容
    SendDate: string; // 發送日期 (ISO 8601 格式)
    TopNews: boolean; // 是否為置頂新聞
  }
  
const EpMainPage: React.FunctionComponent<IPageProps> = props => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [iSec,setIsec]=useState<IEPSchedule[]>([]);
    const [openObjModal, setOpenObjModal] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const [NewsItemList,setNewItemList]  = useState<NewsItem[]>([]) ;

    useEffect(() => {
        getInfoFrNewAdByClass()
    }, [])

   const getDateRange=(bdate:string,edate:string):String=>{
        let result="";
        result=bdate.slice(6,7)+"~"+edate.slice(9,10);
        return result;
   }
   const getInfoFrNewAdByClass= async  ()=>{
   let rsp = await getAdNewByClass(announcementClassName) as NewsItem []
   setNewItemList(rsp)
   console.log("getInfoFrNewAdByClass",rsp)



   }

   const handlecheck=()=>{
    const result=checkFileExists("upload113/EP001-01","EP001-01.pdf","1");
    console.log(result);
   }
     
    return (
        <>
        <TopNavPage1/>
       
        <div className="section pt-3 pt-lg-5">
            <div className="container px-3 px-md-4">
                <div className="d-flex align-items-center flex-column flex-xl-row">
                    <h5 className="title mb-3 me-3">線上探究<div className="ms-2">EP,Explore Process</div></h5>
                    <div className="mx-auto ms-auto me-md-0 text-center">
                    <a className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button" onClick={()=>handleLink("/epabout")}>
                        EP 說明</a>
                    <a href="/epFAQ" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
                        常見問題</a>
                    <div className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" data-bs-toggle="modal" onClick={()=>setOpenObjModal(true)}
                        data-bs-target="#plan">實施計畫</div>
                    {/* <div className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" data-bs-toggle="modal"
                        data-bs-target="#plan2" onClick={()=>handlecheck()}>決賽方式</div>
                    <a href="/epResult" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button" onClick={()=>handleLink("/epresult")}>
                        歷年成果</a>
                    <a href="epExcellent" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button" onClick={()=>handleLink("/epexcellent")}>
                        特優作品</a> */}
                    <div className="btn btn-outline-secondary btn-round text-nowrap"  data-bs-toggle="modal"
                        data-bs-target="#plan3" onClick={() =>  setShowModal(true)}>活動聯絡</div>
                    </div>
                </div>
                <div className="text-center h4">
                歡迎參加{currentClass}柯華葳線上數位閱讀專題探究競賽
                    {/* <b> 
                        歡迎參加{currentClass}柯華葳線上數位閱讀專題探究競賽
                    </b> */}
                    </div>
                <ScheduleDB/>
                {/* <div className="flex-parent mt-5">
                    <div className="input-flex-container timelined"> 
                        <div className="input text-black" style={{background: '#025224'}} id="readyGo" >
                            <span data-year="" data-info="Ready Go!"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center" >1
                            <span className="text-black" data-year="2/15-3/1" data-info="報名"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center" >2
                            <span data-year="3/1" data-info="上傳報名表截止"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center">3
                            <span data-year="3/4" data-info="報名完成公告"></span>
                        </div>
                        <div className={"input text-white fw-bolder text-center"}>4
                            <span data-year="3/6~3/25" data-info="第1&2階段"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center">5
                            <span data-year="4/18" data-info="線上回饋公告"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center">6
                            <span data-year="4/19~6/11" data-info="第3&4&5階段"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center">7
                            <span data-year="6/12~6/27" data-info="線上初審"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center">8
                            <span data-year="6/28" data-info="初賽結果公告"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center">9
                            <span data-year="6/29~7/16" data-info="上傳決賽發表簡報"></span>
                        </div>
                        <div className="input text-white fw-bolder text-center" style={{background: '#025224'}} id="finish">
                            <span data-year="7/24~7/25" data-info="決賽"></span>
                        </div>
                    </div>
                 </div> */}

            <div className="card p-3 p-md-4">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center justify-content-center text-second bg-second text-center border-radius-sm mb-3 fw-bolder" style={{width:'180px'}}>
                    競賽公告
                    </div>
                    <div className="flex-item mx-auto ms-auto me-md-0 text-center">
                        <a href="/eplogin" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
                        登入(承辦人、學生和指導老師分別以編號登入)</a>
                        <a href="/epNewPage" className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3" type="button">
                        承辦人申請</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <ol className="text-dblue text-justify">

                            {NewsItemList.map((item)=>{

                                return (<><li>
                                    {item.Context}
                                    </li></>)
                            })}
                        {/* <li>【活動預告】{currentClass}柯華葳線上探究競賽預計於2025/2/11開放報名，敬請期待！</li>
                        <li>開放「探究學習」，報名後可選擇只參與學習或競賽，實施方式詳見「附件一：探究學習之說明」。</li>
                        <li>完成簽名之「參賽聲明書」留校備查即可，無須回傳。</li>
                        <li>「探究歷程階段3、4、5」合併在同一時間開始與完成。</li>
                        <li>決賽舉辦時間遇天然災害停班停課的因應措施，詳見十二(四)條。</li> */}
                        </ol>            
                    </div>
                    <div className="col-12 col-lg-6">
                        <img src="./assets/img/ep/114用圖.jpeg" alt="" className="w-100"/>
                    </div>
                </div>
            </div>
            {(isDisplay) ?
            
            <div className="card-group mt-4">
                <div className="card p-3 p-md-4">
                <div className="text-second bg-second text-center border-radius-sm mb-3 fw-bolder" style={{width:'180px'}}>
                    報名審核通過的小隊
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-between flex-wrap">
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國小A組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國小B組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國中C組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國中D組</div>
                </div>
                <div className="text-second text-center">尚未公布名單</div>
                </div>
                <div className="card p-3 p-md-4">
                <div className="text-second bg-second text-center border-radius-sm mb-3 fw-bolder" style={{width:'180px'}}>
                    決賽名單
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-between flex-wrap">
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#nameA">● 國小A組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#nameA">● 國小B組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#nameA">● 國中C組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#nameA">● 國中D組</div>
                </div>
                </div>

                <div className="card p-3 p-md-4">
                <div className="text-second bg-second text-center border-radius-sm mb-3 fw-bolder" style={{width:'180px'}}>
                    決賽獲獎隊伍
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-between flex-wrap">
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國小A組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國小B組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國中C組</div>
                    <div className="text-second dots pointer" data-bs-toggle="modal" data-bs-target="#awardA">● 國中D組</div>
                </div>
                <div className="d-flex flex-column flex-lg-row mx-auto text-second my-3">
                    <div className="my-3 my-lg-0 me-4 pointer" data-bs-toggle="modal" data-bs-target="#special">
                    <span className="material-icons-outlined me-2" style={{verticalAlign:'sub'}}>thumb_up_alt</span>報導者特別獎
                    </div>
                    <div className="pointer" data-bs-toggle="modal" data-bs-target="#comment">
                    <span className="material-icons-outlined me-2" style={{verticalAlign:'sub'}}>thumb_up_alt</span>競賽總評
                    </div>
                </div>
                </div>              
            </div> : null
            }


            </div>
        </div>
        <ContactModal show={showModal} handleClose={() => setShowModal(false)} />
        <ObjectModal title="實施計畫" isOpen={openObjModal} setOpenModal={setOpenObjModal} objectURL="./imagedoc/114年度柯華葳線上數位閱讀專題探究學習與競賽實施計畫.pdf"/>
        <FooterPage/>
        </>
    );
}

export default EpMainPage;